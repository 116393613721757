import { all, fork, put, takeEvery } from '@redux-saga/core/effects'
import { networksToAPIMap } from '../../constants/NavigationConstants'
import API from '../api/api'
import { setNetwork } from '../network/NetworkActions'
import { NetworkTypes } from '../network/NetworkTypes'
import { NetworkActionTypes } from '../network/NetworkActionTypes'

//watcher
function* updateNetwork() {
  yield takeEvery(NetworkActionTypes.UPDATE_NETWORK, setSelectedNetwork)
}

//worker
function* setSelectedNetwork(action: NetworkTypes) {
  yield put(setNetwork(action.network))
  yield (API.defaults.baseURL = networksToAPIMap.get(action.network))
}

export function* NetworkSaga() {
  yield all([fork(updateNetwork)])
}
