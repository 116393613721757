import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { CheveronDown, CheveronUp } from '../../assets/components'
import { ActionTypes } from './ActionTypes'
import { useAppDispatch } from '../../store/store'
import { DropdownOption, NavBarLinkItem } from '../../constants/NavigationConstants'
import './NavBarLink.css'
import { select } from 'redux-saga/effects'

type NavBarLinkProps = {
  navBarLink: NavBarLinkItem
  id: number
  closeHamburgerMenu?: () => void
  updateSelection: (selectedIndex: number, optionIndex?: number) => void
}

const NavBarLink: React.FC<NavBarLinkProps> = ({ navBarLink: { dropdownOptions = [], name, link = '', dropdownIcon = false, selected = false, redirect = false }, id, closeHamburgerMenu, updateSelection }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [current, setCurrent] = useState<boolean>(false)
  const [dropdown, setDropdown] = useState<boolean>(false)

  const toggleDropdown = (): void => {
    setDropdown((dropdown) => !dropdown)
  }
  const closeDropdown = (): void => {
    setDropdown(false)
  }
  const dispatchAction = (actionType: ActionTypes) => {
    dispatch(actionType)
  }
  const onClickNavLink = (
    e: React.MouseEvent,
    redirect: boolean,
    id: number,
    closeHamburgerMenu = () => {},
    updateSelection: (selectedIndex: number, selectedOptionIndex?: number) => void,
    selectedOptionIndex?: number,
    option?: DropdownOption,
    action?: (option: DropdownOption) => ActionTypes
  ) => {
    if (!redirect) e.preventDefault()
    if (action && option) dispatchAction(action(option))
    if (!isDropdownNavLink(dropdownOptions) || dropdownOptionSelected(selectedOptionIndex)) {
      closeHamburgerMenu()
      updateSelection(id, selectedOptionIndex)
    }
  }

  const isDropdownNavLink = (dropdownOptions: DropdownOption[]) => dropdownOptions.length > 0

  const dropdownOptionSelected = (selectedOptionIndex?: number) => selectedOptionIndex !== undefined

  // Change Relays link to 'active' for /relays/...
  useEffect(() => {
    if (location.pathname.includes(name)) {
      setCurrent(true)
      updateSelection(id)
    } else {
      setCurrent(false)
    }
  }, [location])

  return (
    <div className="nav-bar-link-wrapper" onMouseLeave={closeDropdown}>
      <div>
        <NavLink
          to={link}
          className={() => {
            return `nav-link ${current || selected ? 'active' : ''}`
          }}
          onClick={(e) => {
            onClickNavLink(e, redirect, id, closeHamburgerMenu, updateSelection)
            toggleDropdown()
          }}
        >
          <div className="nav-link-selector">
            <span className="nav-link-name">{name}</span>
            {dropdownIcon && dropdownOptions.length > 0 ? dropdown ? <CheveronUp height="20px" width="20px" /> : <CheveronDown height="20px" width="20px" /> : null}
          </div>
        </NavLink>
      </div>
      {dropdownOptions.length > 0 && dropdown && (
        <div className="dropdown-menu">
          <ul>
            {dropdownOptions.map((option, index) => {
              const { name, link = '/', selected = false, redirect = false, action } = option
              return (
                <li className="dropdown-item" key={index}>
                  <NavLink
                    to={link}
                    className={() => {
                      return `nav-link-option ${selected ? 'active' : ''} dropdown-item`
                    }}
                    onClick={(e) => {
                      onClickNavLink(e, redirect, id, closeHamburgerMenu, updateSelection, index, option, action)
                      closeDropdown()
                    }}
                  >
                    {name.charAt(0).toUpperCase() + name.slice(1)}
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default NavBarLink
