import { NavLink } from 'react-router-dom'
import './DetailsColumn.css'

type props = {
  relayId: string
}

export const DetailsColumn: React.FC<props> = (relayId) => {
  return (
    <NavLink to={`/relays/${relayId}`} className={`details`}>
      Details
    </NavLink>
  )
}
