import { StatsActionTypes } from './StatsActions'
import { OverviewModel, PaginationData } from '../../constants/DataModels'
import { StatsTypes, StatsState } from './StatsTypes'

const initialOverviewState: OverviewModel = {
  relay_count: 0,
  transaction_count: 0,
  unlinked_count: 0,
  in_transit_to_carbon_count: 0,
  in_transit_from_carbon_count: 0,
  in_transit_count: 0,
}

const initialState: StatsState = {
  blockchains: {
    data: [],
    pagination: {} as PaginationData,
  },
  overview: initialOverviewState,
  dayTx: 0,
}

const StatsReducer = function (state = initialState, action: StatsTypes) {
  switch (action.type) {
    case StatsActionTypes.SET_BLOCKCHAIN_DATA: {
      return {
        ...state,
        blockchains: action.payload,
      }
    }

    case StatsActionTypes.SET_OVERVIEW_DATA: {
      return {
        ...state,
        overview: action.payload,
      }
    }

    case StatsActionTypes.SET_DAY_RELAY: {
      return {
        ...state,
        dayTx: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default StatsReducer
