import { ActionTypes } from '../components/NavBar/ActionTypes'
import { updateNetwork } from '../store/network/NetworkActions'
import { Network } from './network'

export type NavBarLinkItem = {
  name: string
  link?: string
  redirect?: boolean
  dropdownOptions?: DropdownOption[]
  selected?: boolean
  dropdownIcon?: boolean
}
export type DropdownOption = {
  name: string
  link?: string
  selected?: boolean
  redirect?: boolean
  action?: (props: any) => ActionTypes
}

export const MAIN_NAV_BAR_LINKS: NavBarLinkItem[] = [
  {
    name: 'home',
    redirect: true,
    selected: true,
  },
  {
    name: 'relays',
    link: 'relays',
    redirect: true,
  },
  {
    name: 'transactions',
    dropdownOptions: [
      { name: 'Transfers', link: 'transfer_payloads', redirect: true },
      { name: 'Asset Registrations', link: 'register_assets', redirect: true },
      { name: 'Events', link: 'events', redirect: true }
    ],
    dropdownIcon: true,
  },
  // {
  //   name: 'relayers',
  //   link: '/relayers',
  //   redirect: true,
  // },
]

const networkDispatchAction = (networkSelected: DropdownOption) => {
  return updateNetwork(networkSelected.name as Network)
}

export const NETWORK_SELECTION: NavBarLinkItem[] = [
  {
    name: 'network',
    redirect: false,
    dropdownOptions: [
      {
        name: 'mainnet',
        selected: true,
        action: networkDispatchAction,
        redirect: false,
      },
      {
        name: 'testnet',
        selected: false,
        action: networkDispatchAction,
        redirect: false,
      },
    ],
    selected: true,
    dropdownIcon: true,
  },
]

export const networksToAPIMap = new Map<Network, string>([
  [Network.MainNet, process.env.REACT_APP_API_ENDPOINT || 'https://hydrogen-api.carbon.network'],
  [Network.TestNet, 'https://test-hydrogen-api.carbon.network'],
  [Network.DevNet, 'https://dev-hydrogen-api.carbon.network'],
])

