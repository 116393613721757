import React from 'react'
import { useNavigate } from 'react-router-dom'

type props = {
  path: string
}
const ViewMoreButton: React.FC<props> = ({ path }) => {
  const navigate = useNavigate()

  const handleViewMore = (path: string) => {
    navigate(path)
  }

  return (
    <div className='view-more-wrapper'>
      <button className='view-more-button' onClick={() => handleViewMore(path)}>
        View More
      </button>
    </div>
  )
}

export default ViewMoreButton
