import './Card.css'

type CardProps = {
  title: string
  description: string
}

const Card: React.FC<CardProps> = ({ title, description }) => {
  return (
    <div className='card-container'>
      <h4 className='card-title'><span>{title}</span></h4>
      <h1 className='card-description'>{description}</h1>
    </div>
  )
}

export default Card
