import React from 'react'
import { ConfirmationStatus, EventNames, FlowType } from 'src/constants/BlockchainConstants'
import { EventData, RelayDetailModel } from 'src/constants/DataModels'
import './Timeline.css'
import { capitalize } from 'lodash'
import { ExplorerTxHashLink } from 'src/components/shared/ExplorerTxHashLink'

type props = {
  events?: EventData[]
  relay?: RelayDetailModel
}

enum EventFrom {
  Carbon = 'carbon',
  Bridge = 'bridge',
  ExternalChain = 'evm',
}

interface EventWithSource {
  name: string,
  eventFrom: string,
}

// axelar outbound events in order
export const AxelarOutboundEvents: Array<EventWithSource> = [
  {
    name: EventNames.NewPendingActionEvent,
    eventFrom: EventFrom.Carbon,
  },
  {
    name: EventNames.BridgeSentEvent,
    eventFrom: EventFrom.Carbon,
  },
  {
    name: EventNames.BridgeAcknowledgedEvent,
    eventFrom: EventFrom.Carbon,
  },
  {
    name: EventNames.ContractCallSubmitted,
    eventFrom: EventFrom.Bridge,
  },
  {
    name: EventNames.ContractCallApproved,
    eventFrom: EventFrom.ExternalChain,
  },
  {
    name: EventNames.ContractCallExecuted,
    eventFrom: EventFrom.ExternalChain,
  },
]

// axelar inbound events in order
export const AxelarInboundEvents: Array<EventWithSource> = [
  {
    name: EventNames.ContractCall,
    eventFrom: EventFrom.ExternalChain,
  },
  {
    name: EventNames.EVMEventConfirmed,
    eventFrom: EventFrom.Carbon,
  },
  {
    name: EventNames.BridgeReceivedEvent,
    eventFrom: EventFrom.Bridge,
  },
]

interface TimelineDetail extends EventWithSource {
  name: string,
  blockchain: string,
  status: string
  tx_hash: string
}

function getTimelineDetails(eventsWithSources: Array<EventWithSource>, relayDetail: RelayDetailModel): Array<TimelineDetail> {
  const { bridge, flow_type, source_blockchain, destination_blockchain } = relayDetail!
  return eventsWithSources.map((es) => {
    let blockchain: string
    switch (es.eventFrom) {
      case EventFrom.Carbon: {
        blockchain = 'carbon'
        break
      }
      case EventFrom.Bridge: {
        blockchain = bridge
        break
      }
      case EventFrom.ExternalChain: {
        blockchain = flow_type === FlowType.In ? source_blockchain : destination_blockchain
        break
      }
      default:
        throw new Error('event source not declared')
    }
    return {
      ...es,
      name: es.name,
      blockchain,
      tx_hash: '',
      status: ConfirmationStatus.unconfirmed,
    }
  })
}

const Timeline: React.FC<props> = ({ events, relay }) => {
  let eventGroup = relay?.flow_type === FlowType.In ? AxelarInboundEvents : AxelarOutboundEvents
  let displayEvents: TimelineDetail[] = getTimelineDetails(eventGroup, relay!)

  displayEvents.forEach((es) => {
    const event = events?.find((e) => e.name === es.name)
    if (es.status === ConfirmationStatus.error) {
      return {
        name: es.name,
        status: ConfirmationStatus.error
      }
    } else if (event) {
      es.status = event.confirmation_status
      es.tx_hash = event.tx_hash
    }
  })

  return (
    <div className="timeline-container">
      {
        displayEvents.map((event) => {
          return (
            <div key={event.name} className="timeline-block">
              <div className={`marker marker-${event.status}`}></div>
              <div className="timeline-content">
                <div className="timeline-content content">
                  {event.name}
                  <br/>
                  <div className="tx">
                    ({capitalize(event.blockchain)}) <ExplorerTxHashLink blockchain={event.blockchain} hash={event.tx_hash} shouldTruncate={true} />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Timeline
