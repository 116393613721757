import { PaginationData } from '../../constants/DataModels'
import { EventTypes, EventState } from './EventTypes'
import { EventActionTypes } from './EventActions'
import {
  DEFAULT_END_DATE_FORMAT,
  DEFAULT_START_DATE_FORMAT,
} from '../../components/Tables/DateRangeFilter'

const initialState: EventState = {
  data: {
    data: [],
    pagination: {} as PaginationData,
  },
  filters: {
    start_time: DEFAULT_START_DATE_FORMAT,
    end_time: DEFAULT_END_DATE_FORMAT,
  },
  sort: {
    id: '',
    desc: false,
  },
  search: {
    data: [],
    pagination: {} as PaginationData,
  },
}

//TODO: Add fail action
const EventReducer = function (
  state = initialState,
  action: EventTypes
) {
  switch (action.type) {
    case EventActionTypes.SET_EVENT: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case EventActionTypes.ADD_FILTER: {
      let newFilterState = { ...state.filters }
      newFilterState[action.column] = action.row
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case EventActionTypes.REMOVE_FILTER: {
      let newFilterState = { ...state.filters }
      delete newFilterState[action.column]
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case EventActionTypes.SET_SORT: {
      return {
        ...state,
        sort: {
          id: action.id,
          desc: action.desc,
        },
      }
    }

    case EventActionTypes.SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      }
    }
    

    default: {
      return state
    }
  }
}

export default EventReducer
