import React from 'react'
import PayloadTransfer from 'src/components/DetailsPage/sections/PayloadTransfer'
import { RegisterAssetData, TransferPayloadData } from 'src/constants/DataModels'
import PayloadRegisterAsset from 'src/components/DetailsPage/sections/PayloadRegisterAsset'
import AttributeTable from 'src/components/shared/AttributeTable'
import AttributeRow from 'src/components/shared/AttributeRow'
import ReactJson from 'react-json-view'

type Props = {
  payload_type: string
  payload:
    | TransferPayloadData
    | RegisterAssetData
    | any
  sourceBlockchain: string
  destinationBlockchain: string
}

const Payload: React.FC<Props> = ({ payload_type, payload, sourceBlockchain, destinationBlockchain }) => {
  if (!payload) {
    return <div/>
  }
  switch (payload_type) {
    case 'transfer': {
      return <PayloadTransfer payloadData={payload} sourceBlockchain={sourceBlockchain} destinationBlockchain={destinationBlockchain} />
    }
    case 'register_asset': {
      return <PayloadRegisterAsset payloadData={payload} sourceBlockchain={sourceBlockchain} destinationBlockchain={destinationBlockchain} />
    }
    default: {
      return (
        <AttributeTable>
          <AttributeRow name={'Flow Type'}>{payload.flow_type}</AttributeRow>
          <AttributeRow name={'Payload Type'}>{payload_type}</AttributeRow>
          <AttributeRow name={'Decoded Payload'}>
          <ReactJson src={payload.decoded_payload} style={ { overflowY: 'auto' } } shouldCollapse={({ name }) => name === 'root'}/>
          </AttributeRow>
        </AttributeTable>
      )
    }
  }
  return <div>Payload not available</div>
}

export default Payload
