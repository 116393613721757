import { TokenModel } from '../../constants/DataModels'
import {
  RequestTokenData,
  SetTokenData,
} from './TokenTypes'

export enum TokenActionTypes {
  REQUEST_TOKEN_DATA = 'REQUEST_TOKEN_DATA',
  SET_TOKEN_DATA = 'SET_TOKEN_DATA',
}

export const requestTokenData = (): RequestTokenData => {
  return {
    type: TokenActionTypes.REQUEST_TOKEN_DATA,
  }
}

export const setTokenData = (data: TokenModel): SetTokenData => {
  return {
    type: TokenActionTypes.SET_TOKEN_DATA,
    payload: data,
  }
}
