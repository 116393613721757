import { DEFAULT_END_DATE_FORMAT, DEFAULT_START_DATE_FORMAT } from 'src/components/Tables/DateRangeFilter'

export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_OFFSET = 0
export const DEFAULT_PAGE_INDEX = Math.ceil(DEFAULT_OFFSET / DEFAULT_PAGE_SIZE)
export const DEFAULT_FILTER_STATE = {
  start_time: DEFAULT_START_DATE_FORMAT,
  end_time: DEFAULT_END_DATE_FORMAT,
}
export const DEFAULT_SORT_STATE = [{ id: 'created_at', desc: true }]
