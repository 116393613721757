import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
  FilterState,
  TransferPayloadData,
  TransferPayloadModel,
} from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { TransferPayloadActionTypes, setTransferPayload } from '../transfer/TransferPayloadActions'
import { RequestTransferPayload } from '../transfer/TransferPayloadTypes'

//watchers
function* getTransferPayloadWatcher() {
  yield takeLatest(
    TransferPayloadActionTypes.REQUEST_TRANSFERPAYLOAD,
    getTransferPayloadData
  )
}

//workers
function* getTransferPayloadData(action: RequestTransferPayload) {
  yield runSagaTask('runTransferPayloadSaga', function* () {
    const TransferPayloadData = (yield call(
      getTransferPayloadDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<TransferPayloadModel>

    yield put(setTransferPayload(TransferPayloadData.data))
  })
}

//API call
async function getTransferPayloadDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<TransferPayloadData>
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })

  const sort = sortState.desc ? '-' + sortState.id : sortState.id

  return await API.get<TransferPayloadModel>(
    `/transfer_payloads?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )
}

export function* TransferPayloadSaga() {
  yield all([fork(getTransferPayloadWatcher)])
}
