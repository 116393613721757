import axios from 'axios'
const rootUrl =
  process.env.REACT_APP_API_ENDPOINT || 'https://hydrogen-api.carbon.network'

const API = axios.create({
  baseURL: `${rootUrl}`,
  headers: { 'Cache-Control': 'no-cache' },
})

export default API
