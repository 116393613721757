import React from 'react'
import './Summary.css'
import { RelayDetailModel } from 'src/constants/DataModels'
import StatusTag from 'src/components/shared/StatusTag'
import { formatBridge, formatChain, formatTime } from 'src/constants/ConversionUtils'
import AttributeValue from 'src/components/DetailsPage/AttributeValue'
import { capitalize } from 'lodash'
import { ExplorerTxHashLink } from 'src/components/shared/ExplorerTxHashLink'
import { FaArrowRight } from 'react-icons/fa'

type Props = {
  title: string
  data: RelayDetailModel
}

const Events: React.FC<Props> = ({ title, data }) => {
  const {
    events
  } = data
  const sortedEvents = events ? events.sort((a, b) => parseInt(a.block_time) - parseInt(b.block_time)) : []
  return (
    <div>
      <h4 className="section-title">{title}</h4>
      <div className="summary-content">
        <table>
          <thead>
          <th>Name</th>
          <th>Chain</th>
          <th>Tx</th>
          <th>Height</th>
          <th>Created</th>
          </thead>
          <tbody>
          {sortedEvents && sortedEvents.map(event => (
            <tr key={event.id}>
              <td>{event.name}</td>
              <td>{formatChain(event.blockchain)}</td>
              <td>
                <ExplorerTxHashLink blockchain={event.blockchain} hash={event.tx_hash} />
              </td>
              <td>{event.block_height}</td>
              <td>{formatTime(new Date(parseInt(event.block_time)))}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Events
