import { combineReducers } from 'redux'
import RelayReducer, { BridgingRelayReducer } from './relays/RelayReducer'
import TransferPayloadReducer from './transfer/TransferPayloadReducer'
import RegisterAssetReducer from './registerasset/RegisterAssetReducer'
import StatsReducer from './stats/StatsReducer'
import MonitorReducer from './monitor/MonitorReducer'
import EventReducer from './events/EventReducer'
import QueueReducer from './queue/QueueReducer'
import NetworkReducer from './network/NetworkReducer'
import loadingTaskReducer from './loadingTask/LoadingTaskReducer'
import TokenReducer from './token/TokenReducer'
import RelayerReducer from './relayers/RelayerReducer'
export default combineReducers({
  RelayReducer,
  BridgingRelayReducer,
  TransferPayloadReducer,
  RegisterAssetReducer,
  StatsReducer,
  MonitorReducer,
  EventReducer,
  QueueReducer,
  NetworkReducer,
  loadingTaskReducer,
  TokenReducer,
  RelayerReducer
})
