/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import './TransferPayloadPage.css'

import { SortingRule, usePagination, useSortBy } from 'react-table'
import {
  FilterData,
  FilterState,
  TokenDecimal,
  TransferPayloadData,
} from '../../constants/DataModels'
import Table, { TableConfig } from '../Tables/Table'
import { TRANSFER_PAYLOAD_COLUMNS } from '../Tables/TableColumns'

import {
  formatAmount,
  formatSnakeCaseToTitleCase,
} from 'src/constants/ConversionUtils'
import { DEFAULT_FILTER_STATE, DEFAULT_OFFSET, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_SORT_STATE } from 'src/constants/PageConstants'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { requestTokenData } from '../../store/token/TokenActions'
import { TokenState } from '../../store/token/TokenTypes'
import {
  addTransferPayloadFilter,
  removeTransferPayloadFilter,
  requestTransferPayload,
} from '../../store/transfer/TransferPayloadActions'
import { TransferPayloadState } from '../../store/transfer/TransferPayloadTypes'
import Breadcrumb from '../Breadcrumb/Breadcrumb'


const TransferPayloadPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector
    
  // state from transfer payload store
  const transferpayloads: TransferPayloadState = useAppSelector(
    (state) => state.TransferPayloadReducer
  )
  const [transferPayloadError, setTransferPayloadError] =
    useState<Error | null>(null)
  const transferPayloadLoading: boolean = useTaskSubscriber(
    'runTransferPayloadSaga'
  )

  // state from tokens store
  // const [tokenOptions, setTokenOptions] = useState([''])
  const [tokenDecimals, setTokenDecimals] = useState<TokenDecimal[]>([])
  const tokens: TokenState = selector((state) => state.TokenReducer)

  // format token data for table
  const formatFilterOptions = () => {
    const tokenAssets = tokens.token.data
    // const formattedAsset = Array.from(new Set(tokenAssets.map((row) => row.id)))
    // formattedAsset.concat(
    //   Array.from(new Set(tokenAssets.map((row) => row.token_address)))
    // )
    const tokenDecimalData = tokenAssets.map((row) => ({
      id: row.id,
      decimals: row.decimals,
    }))

    setTokenDecimals(tokenDecimalData)
    // setTokenOptions(formattedAsset)
  }

  // with pagination and filters enabled
  const { pagination, data } = transferpayloads.data
  const filterState = transferpayloads.filters
  const [pageCount, setPageCount] = useState(
    Math.ceil(pagination.total / DEFAULT_PAGE_SIZE)
  )

  // format transfer payload data for table
  const formatData = () => {
    const formattedData = data.map((row) => {
      const tokenItem = tokenDecimals.find(
        (item) => item.id === row.carbon_token_id
      )

      const formattedAmount = tokenItem
        ? formatAmount(row.amount, tokenItem.decimals)
        : row.amount
      return {
        ...row,
        amount: formattedAmount,
      }
    })

    return formattedData
  }

  const network = selector((state) => state.NetworkReducer.network)

  // get table data for relays table
  const handleDataRequest = (
    offset: number,
    pageSize: number,
    filterState: FilterState,
    sortState: SortingRule<TransferPayloadData>[]
  ) => {
    try {
      dispatch(
        requestTransferPayload(offset, pageSize, filterState, sortState[0])
      )
    } catch (e: any) {
      setTransferPayloadError(e)
    }
  }

  // handle remove filter
  const handleRemoveFilter = useCallback((column: string) => {
    dispatch(removeTransferPayloadFilter(column))
  }, [])

  // handle add filter
  const handleAddFilter = useCallback((column: string, row: string) => {
    dispatch(addTransferPayloadFilter(column, row))
  }, [])

  // initial render to set states
  useEffect(() => {
    handleDataRequest(
      DEFAULT_OFFSET,
      DEFAULT_PAGE_SIZE,
      DEFAULT_FILTER_STATE,
      DEFAULT_SORT_STATE
    )
    dispatch(requestTokenData())
  }, [network])

  // set initial page count and filter options state
  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))
    formatFilterOptions()
  }, [pagination.total])

  // filter options
  const filterOptions: FilterData[] = [
    {
      name: 'Address',
      column: 'address',
    },
    {
      name: 'Search Asset',
      column: 'search_asset',
    },
    {
      name: 'From Asset',
      column: 'from_asset',
    },
    {
      name: 'To Asset',
      column: 'to_asset',
    },
    {
      name: 'Type',
      column: 'transfer_payload_type',
      rows: ['token_transfer', 'token_bridging'],
      rowFormatter: formatSnakeCaseToTitleCase,
    },
  ]

  // table options
  const tableConfig: TableConfig<TransferPayloadData> = {
    options: {
      columns: TRANSFER_PAYLOAD_COLUMNS,
      data: formatData(),
      initialState: {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: DEFAULT_SORT_STATE,
        hiddenColumns: ['from_asset_hash', 'to_asset_hash'],
      },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  //pagination enabled
  const paginationConfig = {
    paginationData: pagination,
    onPageCountChange: setPageCount,
  }

  //filters enabled
  const filterConfig = {
    filterOptions: filterOptions,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
    filterState: filterState,
    dateRangeFilter: true,
  }

  return (
    <div>
      <div className="container main-wrapper">
        <Breadcrumb pages={['home', 'transfers']} />
        <Table
          tableConfig={tableConfig}
          isLoading={transferPayloadLoading}
          error={transferPayloadError}
          name="transfers"
          onDataRequest={handleDataRequest}
          paginationConfig={paginationConfig}
          filterConfig={filterConfig}
        />
      </div>
    </div>
  )
}

export default TransferPayloadPage
