import { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { PluginHook, Row, SortingRule, TableOptions, useTable } from 'react-table'
import { FilterData, FilterState, PaginationData } from '../../constants/DataModels'
import DateRangeFilter from './DateRangeFilter'
import InputFilter from './InputFilter'
import SelectFilter from './SelectFilter'
import './Table.css'
import ViewMoreButton from './ViewMoreButton'

import { CheveronDown, CheveronUp } from '../../assets/components'

export type TableProps<T extends Object> = {
  tableConfig: TableConfig<T>
  isLoading: boolean
  error: Error | null
  name: string
  onDataRequest: (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<T>[]) => void
  paginationConfig: PaginationConfig
  filterConfig: FilterConfig
  viewMorePath?: string
}

export type TableConfig<T extends Object> = {
  options: TableOptions<T>
  plugins?: PluginHook<T>[]
}

type PaginationConfig = {
  paginationData: PaginationData
  onPageCountChange: React.Dispatch<React.SetStateAction<number>>
}

type FilterConfig = {
  filterOptions: FilterData[]
  onAddFilter: (column: string, row: string) => void
  onRemoveFilter: (column: string) => void
  filterState: FilterState
  dateRangeFilter?: boolean
}

const Table = <T extends Object>(props: TableProps<T>) => {
  const {
    tableConfig: { options, plugins = [] },
    isLoading,
    error,
    name,
    onDataRequest: requestData,
    paginationConfig: { paginationData, onPageCountChange: setPageCount },
    filterConfig: { filterOptions, onAddFilter, onRemoveFilter, filterState, dateRangeFilter },
    viewMorePath,
  } = props

  const tableInstance = useTable(options, ...plugins)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    gotoPage,
    pageCount,
    setPageSize,
    setSortBy,

    state,
  } = tableInstance

  const { pageSize, pageIndex, sortBy } = state
  const offset: number = pageSize * pageIndex
  const isEmptyTable = paginationData.total === 0 || paginationData.total === undefined //check if table has data

  //Update table on page index change, filter change, page size change
  useEffect(() => {
    requestData(offset, pageSize, filterState, sortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex, filterState, sortBy])

  //Update page index
  const handlePageClick = (event: any) => {
    gotoPage(event.selected)
  }

  //Update page size
  const handlePageSize = (event: any) => {
    const newPageSize = event.target.value
    setPageSize(newPageSize)
    setPageCount(Math.ceil(paginationData.total / newPageSize))
    gotoPage(0)
  }

  //Update sorting
  const handleSorting = (id: string) => {
    const sort: SortingRule<T>[] = [
      {
        id,
        desc: !sortBy[0].desc,
      },
    ]
    setSortBy(sort)
  }

  //Render table rows
  const renderRow = (row: Row<T>) => (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
      })}
    </tr>
  )

  return (
    <div>
      <div className="table-wrapper">
        <div className="table-header">
          <h4 className="table-title">{name}</h4>
          {viewMorePath && <ViewMoreButton path={viewMorePath} />}

          {/* Pagination */}
          {page && (
            <div className="react-paginate-wrapper">
              <div className="page-info">
                {!isLoading && (
                  <span>
                    Showing {offset + 1} to {pageIndex === pageCount - 1 ? paginationData.total : +offset + +pageSize} of {paginationData.total} results
                  </span>
                )}
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                onPageChange={handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                forcePage={pageIndex}
                className="pagination-container"
                pageLinkClassName="pagination-index"
                previousLinkClassName="prev-button"
                nextLinkClassName="next-button"
                breakLinkClassName="break"
                activeClassName="active-button"
                disabledLinkClassName="disabled-button"
              />
                <div className="pagination">
                  <div className="page-size-dropdown">
                    <select value={pageSize} onChange={handlePageSize}>
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
            </div>
          )}
        </div>
        {(filterOptions.length || dateRangeFilter || page) && (
          <div className="table-plugins">
            {/* Filter */}
            {(filterOptions.length || dateRangeFilter) && (
              <div className="table-filter">
                {dateRangeFilter && <DateRangeFilter onAddFilter={onAddFilter} />}
                {filterOptions.map((filter) => {
                  if (filter.rows) {
                    return <SelectFilter key={filter.column} filter={filter} onAddFilter={onAddFilter} onRemoveFilter={onRemoveFilter} filterState={filterState} />
                  } else {
                    return <InputFilter key={filter.column} filter={filter} onAddFilter={onAddFilter} onRemoveFilter={onRemoveFilter} filterState={filterState} />
                  }
                })}
              </div>
            )}
          </div>
        )}

        <div className="table-container">
          {/* Table */}
          {error ? (
            <div className="error-feedback">
              <span>ERROR: {error.message}</span>
            </div>
          ) : isLoading ? (
            <div className="table-feedback">
              <span>Loading data...</span>
            </div>
          ) : isEmptyTable ? (
            <div className="table-feedback">
              <span>No data to display</span>
            </div>
          ) : (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div className="header">
                          {' '}
                          {column.render('Header', {
                            onSort: handleSorting,
                          })}
                          <span className="sort-cheveron">{column.isSorted ? column.isSortedDesc ? <CheveronUp className="cheveron-up" /> : <CheveronDown className="cheveron-down" /> : ''}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page
                  ? page.map((row, i) => {
                      prepareRow(row)
                      return renderRow(row)
                    })
                  : rows.map((row, i) => {
                      prepareRow(row)
                      return renderRow(row)
                    })}
              </tbody>
            </table>
          )}
        </div>
        <div className="table-footer" />
      </div>
    </div>
  )
}

export default Table
