import { Route, Routes } from 'react-router-dom'
import HomePage from './HomePage/HomePage'
import NavBar from './NavBar/NavBar'
import RelayersPage from './RelayerPage/RelayersPage'
import RelayPage from './Transactions/RelayPage'
import RegisterAssetPage from './Transactions/RegisterAssetPage'
import TransferPayloadPage from './Transactions/TransferPayloadPage'
import DetailsPage from './DetailsPage/DetailsPage'
import EventsPage from './Transactions/EventsPage'
import PageFooter from './PageFooter/PageFooter'

const Page: React.FC = () => {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='relays' element={<RelayPage />} />
        <Route
          path='relays/:relayId'
          element={<DetailsPage />}
        />
        <Route path='transfer_payloads' element={<TransferPayloadPage />} />
        <Route path='register_assets' element={<RegisterAssetPage />} />
        <Route path='events' element={<EventsPage />} />
        <Route path='relayers' element={<RelayersPage />} />
        <Route path='*' element={<HomePage />} />
      </Routes>
      <PageFooter/>
    </div>
  )
}

export default Page
