import React from 'react'
import 'src/components/DetailsPage/sections/Transaction.css'
import { TransferPayloadData } from 'src/constants/DataModels'
import AttributeRow from 'src/components/shared/AttributeRow'
import AttributeTable from 'src/components/shared/AttributeTable'
import { ExplorerAssetLink } from 'src/components/shared/ExplorerAssetLink'
import { ExplorerAddressLink } from 'src/components/shared/ExplorerAddressLink'
import { formatSnakeCaseToTitleCase } from 'src/constants/ConversionUtils'

type CardProps = {
  payloadData?: TransferPayloadData
  sourceBlockchain: string
  destinationBlockchain: string
}

const PayloadTransfer: React.FC<CardProps> = ({ payloadData, sourceBlockchain, destinationBlockchain }) => {
  if (!payloadData) {
    return <div>Not Available</div>
  }
  const {
    from_asset,
    from_asset_hash,
    from_address,
    from_address_hash,
    to_asset,
    to_asset_hash,
    to_address,
    to_address_hash,
    fee_address,
    fee_address_hash,
    amount,
    transfer_payload_type
  } = payloadData

  return (
    <AttributeTable>
      <AttributeRow name={'Transfer Payload Type'}>{formatSnakeCaseToTitleCase(transfer_payload_type)}</AttributeRow>
      <AttributeRow name={'From Address'}>
        <ExplorerAddressLink blockchain={sourceBlockchain} address={from_address} shouldTruncate={false}/>
      </AttributeRow>
      <AttributeRow name={'From Asset'}>
        <ExplorerAssetLink blockchain={sourceBlockchain} assetIdOrAddress={from_asset} shouldTruncate={false}/>
      </AttributeRow>

      <AttributeRow name={'To Address'}>
        <ExplorerAddressLink blockchain={destinationBlockchain} address={to_address} shouldTruncate={false}/>
      </AttributeRow>
      <AttributeRow name={'To Asset'}>
        <ExplorerAssetLink blockchain={destinationBlockchain} assetIdOrAddress={to_asset} shouldTruncate={false}/>
      </AttributeRow>

      <AttributeRow name={'Fee Address'}>{fee_address}</AttributeRow>
      <AttributeRow name={'Amount'}>{amount}</AttributeRow>

      <AttributeRow name={'From Address (Hash)'}>{from_address_hash}</AttributeRow>
      <AttributeRow name={'From Asset (Hash)'}>{from_asset_hash}</AttributeRow>
      <AttributeRow name={'To Address (Hash)'}>{to_address_hash}</AttributeRow>
      <AttributeRow name={'To Asset (Hash)'}>{to_asset_hash}</AttributeRow>
      <AttributeRow name={'Fee Address (Hash)'}>{fee_address_hash}</AttributeRow>
    </AttributeTable>
  )
}

export default PayloadTransfer
