import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { rootSagas } from './rootSaga'
import logger from 'redux-logger'
import localStorageMiddleware, { loadLocalStorageState } from 'src/hooks/useLocalStorage'

const sagaMiddleWare = createSagaMiddleware()

export const store = configureStore({
  preloadedState: {
    ...loadLocalStorageState()
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sagaMiddleWare,
    localStorageMiddleware,
    logger,
  ],
})

sagaMiddleWare.run(rootSagas)

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
