import React from 'react'
import './AttributeValue.css'

const AttributeValue: React.FC< { attr: string, children?: any }> = ({attr, children}) => {
  return (
    <div className='attr-value-pair'>
      <div className='attr'>{attr}:</div>
      <div className='value'>{children ? children : '---'}</div>
    </div>
  )
}

export default AttributeValue
