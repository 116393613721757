import './SortableHeader.css'

type props = {
  handleSort: (id: string) => void
  headerName: string
  headerId: string
}

export const SortableHeader: React.FC<props> = ({
  handleSort,
  headerName,
  headerId,
}) => {
  return (
    <div>
      <button className='toggle-sort' onClick={() => handleSort(headerId)}>
        {headerName}
      </button>
    </div>
  )
}
