import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { TokenModel } from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { TokenActionTypes, setTokenData } from '../token/TokenActions'
import { RequestTokenData } from '../token/TokenTypes'

//watchers
function* getTokens() {
  yield takeEvery(TokenActionTypes.REQUEST_TOKEN_DATA, getTokenData)
}
//workers
function* getTokenData(action: RequestTokenData) {
  yield runSagaTask('runTokenSaga', function* () {
    const TokenData = (yield call(getTokenDataAPI)) as AxiosResponse<TokenModel>
    yield put(setTokenData(TokenData.data))
  })
}

//API call
async function getTokenDataAPI() {
  return await API.get<TokenModel>(`/tokens?limit=10000`)
}

export function* TokenSaga() {
  yield all([fork(getTokens)])
}
