import React from 'react'
import './DetailsPage.css'

import { RelayDetailModel } from 'src/constants/DataModels'
import Transaction from 'src/components/DetailsPage/sections/Transaction'
import Summary from 'src/components/DetailsPage/sections/Summary'
import Event from 'src/components/DetailsPage/sections/Event'
import Payload from 'src/components/DetailsPage/sections/Payload'
import Events from 'src/components/DetailsPage/sections/Events'
import { formatPayloadType } from 'src/constants/ConversionUtils'
import { FlowType } from 'src/constants/BlockchainConstants'
import ReactJson from 'react-json-view'

type Props = {
  txDetail: RelayDetailModel
}

const Details: React.FC<Props> = ({ txDetail }) => {
  const {
    payload_type,
    flow_type,
    transfer_payload,
    register_asset_payload,
    source_blockchain,
    destination_blockchain,
    bridge,
    source_transaction,
    source_event,
    bridging_transaction,
    bridging_event,
    destination_transaction,
    destination_event,
    payload,
    // events,
  } = txDetail

  const payloadTitle =
    `Carbon Payload: ${formatPayloadType(bridge, flow_type as FlowType, payload_type)}`
  let displayPayload: any
  if (bridge === 'axelar') {
    displayPayload = payload
  } else {
    displayPayload = payload_type === 'transfer' ? transfer_payload : register_asset_payload
  }

  return (
    <div className="relay-details-container">
      <div className="card-container">
        <Summary title={'INFORMATION'} data={txDetail} />
      </div>
      {
        bridge === 'axelar' && (
          <div className="card-container">
            <Events title={'Events'} data={txDetail} />
          </div>
        )
      }

      <div className="card-container">
        <h4 className="section-title">{payloadTitle}</h4>
        <Payload
          payload_type={payload_type}
          payload={displayPayload}
          sourceBlockchain={source_blockchain}
          destinationBlockchain={destination_blockchain}
        />
      </div>

      <div className="card-container">
        <div className="section">
          <h4 className="section-title">SOURCE TRANSACTION</h4>
          <Transaction txInfo={source_transaction} />
        </div>
        <div className="section">
          <h4 className="section-title">SOURCE EVENT</h4>
          <Event eventInfo={source_event} />
        </div>
      </div>

      {bridge !== 'ibc' && (
        <div className="card-container">
          <div className="section">
            <h4 className="section-title">BRIDGING TRANSACTION</h4>
            <Transaction txInfo={bridging_transaction} />
          </div>
          <div className="section">
            <h4 className="section-title">BRIDGING EVENT</h4>
            <Event eventInfo={bridging_event} />
          </div>
        </div>
      )}

      <div className="card-container">
        <div className="section">
          <h4 className="section-title">DESTINATION TRANSACTION</h4>
          <Transaction txInfo={destination_transaction} />
        </div>
        <div className="section">
          <h4 className="section-title">DESTINATION EVENT</h4>
          <Event eventInfo={destination_event} />
        </div>
      </div>
    </div>
  )
}

export default Details
