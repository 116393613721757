
import { useEffect } from 'react'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { FilterData, FilterState } from 'src/constants/DataModels'
import { Cross } from '../../assets/components'
import './SelectFilter.css'

type filterProps = {
  filter: FilterData
  onAddFilter: (column: string, row: string) => void
  onRemoveFilter: (column: string) => void
  filterState: FilterState
}

const SelectFilter: React.FC<filterProps> = ({
                                              filter,
                                              onAddFilter,
                                              onRemoveFilter,
                                              filterState,
                                            }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleAddFilter = (event: any) => {
    const row = event.target.value as string
    const queryParams = queryString.parse(location.search);
    queryParams[filter.column] = row;
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    }, { replace: true });
  }

  const handleRemoveFilter = () => {
    const queryParams = queryString.parse(location.search);
    delete queryParams[filter.column];
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    }, { replace: true });
  }

  const getCurrentFilter = () => {
    return filterState[filter.column] ? filterState[filter.column] : filter.column
  }

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    console.log('queryParams', queryParams[filter.column])
    if (typeof queryParams[filter.column] === 'string') {
      onAddFilter(filter.column, queryParams[filter.column] as string)
    } else {
      onRemoveFilter(filter.column);
    }
  }, [location, filter.column, onAddFilter, onRemoveFilter]);

  return (
    <div className="filter">
      <div className="container">
        <select
          value={getCurrentFilter()}
          onChange={handleAddFilter}
        >
          <option key={filter.column} value={filter.column} disabled>
            {filter.name}
          </option>

          {
            filter.rows && filter.rows.map((row) => (
              <option key={row} value={row}>
                {filter.rowFormatter ? filter.rowFormatter(row) : row}
              </option>
            ))
          }
        </select>
        {filterState[filter.column] ? (
          <button
            className="clear-filter-button"
            onClick={() => handleRemoveFilter()}
          >
            <Cross />
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default SelectFilter
