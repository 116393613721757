import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
  FilterState,
  RegisterAssetData,
  RegisterAssetModel,
} from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { RegisterAssetActionTypes, setRegisterAsset } from '../registerasset/RegisterAssetActions'
import { RequestRegisterAsset } from '../registerasset/RegisterAssetTypes'

//watchers
function* getRegisterAssetWatcher() {
  yield takeLatest(
    RegisterAssetActionTypes.REQUEST_REGISTERASSET,
    getRegisterAssetData
  )
}

//workers
function* getRegisterAssetData(action: RequestRegisterAsset) {
  yield runSagaTask('runRegisterAssetSaga', function* () {
    const RegisterAssetData = (yield call(
      getRegisterAssetDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<RegisterAssetModel>

    yield put(setRegisterAsset(RegisterAssetData.data))
  })
}

//API call
async function getRegisterAssetDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<RegisterAssetData>
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })

  const sort = sortState.desc ? '-' + sortState.id : sortState.id

  return await API.get<RegisterAssetModel>(
    `/register_asset_payloads?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )
}

export function* RegisterAssetSaga() {
  yield all([fork(getRegisterAssetWatcher)])
}
