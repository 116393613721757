import { SortingRule } from 'react-table'
import { RegisterAssetModel, FilterState, RegisterAssetData } from '../../constants/DataModels'

import {
  RequestRegisterAsset,
  SetRegisterAsset,
  AddRegisterAssetFilter,
  RemoveRegisterAssetFilter,
  SetRegisterAssetSort,
} from './RegisterAssetTypes'

export enum RegisterAssetActionTypes {
  REQUEST_REGISTERASSET = 'REQUEST_REGISTERASSET',
  SET_REGISTERASSET = 'SET_REGISTERASSET',
  ADD_FILTER = 'ADD_REGISTERASSET_FILTER',
  REMOVE_FILTER = 'REMOVE_REGISTERASSET_FILTER',
  SET_SORT = 'SET_REGISTERASSET_SORT',
  
}

export const requestRegisterAsset = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<RegisterAssetData>
): RequestRegisterAsset => {
  return {
    type: RegisterAssetActionTypes.REQUEST_REGISTERASSET,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setRegisterAsset = (
  data: RegisterAssetModel
): SetRegisterAsset => {
  return {
    type: RegisterAssetActionTypes.SET_REGISTERASSET,
    payload: data,
  }
}


export const addRegisterAssetFilter = (
  column: string,
  row: string
): AddRegisterAssetFilter => {
  return {
    type: RegisterAssetActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeRegisterAssetFilter = (
  column: string
): RemoveRegisterAssetFilter => {
  return {
    type: RegisterAssetActionTypes.REMOVE_FILTER,
    column,
  }
}

export const setRegisterAssetSort = (id: string, desc: boolean): SetRegisterAssetSort => {
  return {
    type: RegisterAssetActionTypes.SET_SORT,
    id,
    desc
  }
}
