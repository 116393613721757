import React from 'react'
import 'src/components/shared/StatusTag.css'
import { formatRelayStatus } from 'src/constants/ConversionUtils'

type props = {
  status: string
}

const StatusTag: React.FC<props> = ({ status }) => {
  return (
    <div className='status-column'>
      <div className={status}>{formatRelayStatus(status)}</div>
    </div>
  )
}

export default StatusTag