import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
  EventData,
  EventModel,
  FilterState,
} from '../../constants/DataModels'
import eventsapi from '../api/api'; //Change to api
import {
  EventActionTypes,
  setEvent,
} from '../events/EventActions'
import {
  RequestEvent,
} from '../events/EventTypes'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'

//watchers
function* getEventWatcher() {
  yield takeLatest(
    EventActionTypes.REQUEST_EVENT,
    getEventData
  )
}
//workers
function* getEventData(action: RequestEvent) {
  yield runSagaTask('runEventSaga', function* () {
    const eventData = (yield call(
      getEventDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<EventModel>
    yield put(setEvent(eventData.data))
    
  })
  
}

//API call
async function getEventDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<EventData>,
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })

  const sort =
  sortState.desc? '-' + sortState.id : sortState.id

  return await eventsapi.get<EventModel>(
    `/events?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )

}

export function* EventSaga() {
  yield all([fork(getEventWatcher)])
}
