/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import './RegisterAssetPage.css'

import { SortingRule, usePagination, useSortBy } from 'react-table'
import { FilterData, FilterState, RegisterAssetData } from '../../constants/DataModels'
import Table, { TableConfig } from '../Tables/Table'
import { REGISTER_ASSET_COLUMNS } from '../Tables/TableColumns'

import { DEFAULT_FILTER_STATE, DEFAULT_OFFSET, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_SORT_STATE } from 'src/constants/PageConstants'
import { requestTokenData } from 'src/store/token/TokenActions'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { addRegisterAssetFilter, removeRegisterAssetFilter, requestRegisterAsset } from '../../store/registerasset/RegisterAssetActions'
import { RegisterAssetState } from '../../store/registerasset/RegisterAssetTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import Breadcrumb from '../Breadcrumb/Breadcrumb'

const RegisterAssetPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector

  // state from register asset store
  const registerassets: RegisterAssetState = useAppSelector((state) => state.RegisterAssetReducer)
  const [registerAssetError, setRegisterAssetError] = useState<Error | null>(null)
  const registerAssetLoading: boolean = useTaskSubscriber('runRegisterAssetSaga')

  // with pagination and filters enabled
  const { pagination, data } = registerassets.data
  const filterState = registerassets.filters
  const [pageCount, setPageCount] = useState(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))

  const network = selector((state) => state.NetworkReducer.network)

  // get table data for table
  const handleDataRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<RegisterAssetData>[]) => {
    try {
      dispatch(requestRegisterAsset(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setRegisterAssetError(e)
    }
  }

  // handle remove filter
  const handleRemoveFilter = useCallback((column: string) => {
    dispatch(removeRegisterAssetFilter(column))
  }, [])

  // handle add filter
  const handleAddFilter = useCallback((column: string, row: string) => {
    dispatch(addRegisterAssetFilter(column, row))
  }, [])

  // initial render to set states
  useEffect(() => {
    handleDataRequest(DEFAULT_OFFSET, DEFAULT_PAGE_SIZE, DEFAULT_FILTER_STATE, DEFAULT_SORT_STATE)
    dispatch(requestTokenData())
  }, [network])

  // set initial page count and filter options state
  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))
  }, [pagination.total])

  // filter options
  const filterOptions: FilterData[] = [
    {
      name: 'Search Asset',
      column: 'search_asset',
    },
  ]

  // table options
  const tableConfig: TableConfig<RegisterAssetData> = {
    options: {
      columns: REGISTER_ASSET_COLUMNS,
      data: data,
      initialState: {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: DEFAULT_SORT_STATE,
      },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  //pagination enabled
  const paginationConfig = {
    paginationData: pagination,
    onPageCountChange: setPageCount,
  }


  //filters enabled
  const filterConfig = {
    filterOptions: filterOptions,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
    filterState: filterState,
    dateRangeFilter: true,
  }

  return (
    <div>
      <div className="container main-wrapper">
        <Breadcrumb pages={['home', 'asset registrations']} />
        <Table tableConfig={tableConfig} isLoading={registerAssetLoading} error={registerAssetError} name="asset registrations" onDataRequest={handleDataRequest} paginationConfig={paginationConfig} filterConfig={filterConfig} />
      </div>
    </div>
  )
}

export default RegisterAssetPage
