import React from 'react'
import 'src/components/shared/AttributeTable.css'

type props = {
  name: string
  children?: any
}

const AttributeRow: React.FC<props> = ({ name, children }) => {
  return (
    <tr className="attribute-row">
      <td className="attribute-name">{name}</td>
      <td className="attribute-value">{children}</td>
    </tr>
  )
}

export default AttributeRow
