import { PaginationData} from '../../constants/DataModels'
import { RelayerTypes, RelayerState } from './RelayerTypes'
import { RelayerActionTypes } from './RelayerActions'

const initialState: RelayerState = {
    data: {
      data: [],
      pagination: {} as PaginationData,
    },
    filters: {
    },
    sort: {
      id: '',
      desc: false
    }
  }

//TODO: Add fail action
const RelayerReducer = function (
    state = initialState,
    action: RelayerTypes
  ) {
    switch (action.type) {
      case RelayerActionTypes.SET_RELAYER: {
        return {
          ...state,
          data: action.payload,
        }
      }
  
      case RelayerActionTypes.ADD_FILTER: {
        let newFilterState = { ...state.filters }
        newFilterState[action.column] = action.row
        return {
          ...state,
          filters: newFilterState,
        }
      }
  
      case RelayerActionTypes.REMOVE_FILTER: {
        let newFilterState = { ...state.filters }
        delete newFilterState[action.column]
        return {
          ...state,
          filters: newFilterState,
        }
      }

      case RelayerActionTypes.SET_SORT: {
        return {
          ...state,
          sort: {
            id: action.id,
            desc: action.desc
          },
        }
      }

  
      default: {
        return state
      }
    }
  }
  
  export default RelayerReducer