import { LoadingTasks } from '../../constants/DataModels'

import { ActionType, LayoutAction } from './LoadingTaskActions'

export type LayoutState = {
  loadingTasks: LoadingTasks
  tasksRegistry: { [index: string]: any }
}

export const initialState: LayoutState = {
  loadingTasks: {},
  tasksRegistry: {},
}

const updateTaskViewer = (loadingTasks: LoadingTasks) => {
  if (typeof window === 'undefined') return
  ;(window as any).loadingTasks = loadingTasks
}

const loadingTaskReducer = (
  state: LayoutState = initialState,
  action: LayoutAction
) => {
  let loadingTask: any = null
  let taskRegistry: any = null
  let taskName: string | undefined
  switch (action.type) {
    case ActionType.ADD_BACKGROUND_LOADING:
      loadingTask = { ...(state.loadingTasks[action.name]) || {} }
      loadingTask[action.uuid] = true
      taskRegistry = { ...state.tasksRegistry }
      taskRegistry[action.uuid] = action.name // eslint-disable-line

      updateTaskViewer(state.loadingTasks)
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
          [action.name]: loadingTask,
        },
        tasksRegistry: taskRegistry,
      }
    case ActionType.REMOVE_BACKGROUND_LOADING:
      taskName = state.tasksRegistry[action.uuid]

      if (!taskName) {
        return state
      }

      let loadingTaskState = { ...state.loadingTasks }
      loadingTask = { ...loadingTaskState[taskName!] }
      if (!loadingTask || !loadingTask[action.uuid]) {
        return state
      }

      delete loadingTask[action.uuid]

      if (!Object.keys(loadingTask).length) {
        delete loadingTaskState[taskName]
      } else {
        loadingTaskState[taskName] = loadingTask
      }
      taskRegistry = { ...state.tasksRegistry }
      delete taskRegistry[action.uuid]

      updateTaskViewer(loadingTaskState)
      return {
        ...state,
        loadingTasks: loadingTaskState,
        tasksRegistry: taskRegistry,
      }
    default:
      return state
  }
}

export default loadingTaskReducer
