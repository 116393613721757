import { BlockchainModel, OverviewModel } from '../../constants/DataModels'
import {
  RequestBlockchainData,
  SetBlockchainData,
  RequestOverviewData,
  SetOverviewData,
  SetDayRelay,
} from './StatsTypes'

export enum StatsActionTypes {
  REQUEST_BLOCKCHAIN_DATA = 'REQUEST_BLOCKCHAIN_DATA',
  SET_BLOCKCHAIN_DATA = 'SET_BLOCKCHAIN_DATA',
  REQUEST_OVERVIEW_DATA = 'REQUEST_OVERVIEW_DATA',
  SET_OVERVIEW_DATA = 'SET_OVERVIEW_DATA',
  SET_DAY_RELAY = 'SET_DAY_RELAY',
}

export const requestBlockchainData = (): RequestBlockchainData => {
  return {
    type: StatsActionTypes.REQUEST_BLOCKCHAIN_DATA,
  }
}

export const setBlockchainData = (data: BlockchainModel): SetBlockchainData => {
  return {
    type: StatsActionTypes.SET_BLOCKCHAIN_DATA,
    payload: data,
  }
}

export const requestOverviewData = (): RequestOverviewData => {
  return {
    type: StatsActionTypes.REQUEST_OVERVIEW_DATA,
  }
}

export const setOverviewData = (data: OverviewModel): SetOverviewData => {
  return {
    type: StatsActionTypes.SET_OVERVIEW_DATA,
    payload: data,
  }
}

export const setDayRelay = (data: number): SetDayRelay => {
  return {
    type: StatsActionTypes.SET_DAY_RELAY,
    payload: data,
  }
}

