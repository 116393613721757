import React, { useEffect } from 'react'
import { BridgingRelayState, RelayState } from 'src/store/relays/RelayTypes'
import { useParams, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import {
  requestSearch,
  requestTxDetail,
  requestBridgeTxDetail,
} from 'src/store/relays/RelayActions'
import './DetailsPage.css'
import { requestTokenData } from 'src/store/token/TokenActions'
import Details from 'src/components/DetailsPage/Details'

const DetailsPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const { relayId } = useParams()

  const selector = useAppSelector
  const network = selector((state) => state.NetworkReducer.network)
  
  // state from relays store
  const relays: RelayState = useAppSelector((state) => state.RelayReducer)
  const bridgingRelay: BridgingRelayState = useAppSelector(
    (state) => state.BridgingRelayReducer
  )

  const { txDetail } = relays

  useEffect(() => {
    try {
      dispatch(requestTokenData())
      dispatch(requestTxDetail(relayId!))
    } catch (e: any) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relayId, network])

  const navigate = useNavigate()

  useEffect(() => {
    const handleSubmit = () => {
      if (network === 'testnet') {
        navigate(-1)
      }
    }
    handleSubmit()
  }, [navigate, network])

  // For bridging relay
  const searchData = relays.search.data
  const bridgingTxDetail = bridgingRelay.txDetail
  const isBridgingRelay =
    txDetail.transfer_payload?.transfer_payload_type === 'token_bridging'

  // handle processing next relay
  const handleNextRelay = (nextRelayTx: string) => {
    try {
      // search for next relay tx hash and get next relay id
      dispatch(requestSearch(nextRelayTx))
    } catch (e: any) {
      console.error(e)
    }

    let bridgingId: string | null = null
    const matchingRow = searchData.find(
      (row) => row.source_tx_hash === nextRelayTx
    )
    if (matchingRow) {
      bridgingId = matchingRow.id
    }

    if (bridgingId) {
      try {
        // get next relay details
        dispatch(requestBridgeTxDetail(bridgingId))
      } catch (e: any) {
        console.error(e)
      }
    }
  }

  // If it is a bridging relay, find the next relay
  useEffect(() => {
    if (isBridgingRelay) {
      const nextRelay = txDetail.destination_tx_hash // to set the next relay tx hash
      const bridgeRelay = bridgingTxDetail.source_tx_hash
      if (bridgeRelay !== nextRelay) {
        handleNextRelay(nextRelay)
      }
    }
  }, [isBridgingRelay, bridgingTxDetail, txDetail, searchData, handleNextRelay])

  return (
    <div className="container main-wrapper">
      <div className="details-wrapper">
        {isBridgingRelay && <h3 className="relay-title">INBOUND RELAY</h3>}
        <Details txDetail={txDetail} />

        {isBridgingRelay && <h3 className="relay-title">OUTBOUND RELAY</h3>}
        {isBridgingRelay && <Details txDetail={bridgingTxDetail} />}
      </div>
    </div>
  )
}

export default DetailsPage
