/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from 'react'

import { LoadingTasks } from '../constants/DataModels'
import { useAppSelector } from '../store/store'

export default (...tasks: string[]) => {
  const loadingTasks: LoadingTasks = useAppSelector(
    (state) => state.loadingTaskReducer.loadingTasks
  )
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    for (const key of tasks) {
      if (loadingTasks[key]) {
        return setLoading(true)
      }
    }
    return setLoading(false)
  }, [loadingTasks, tasks])

  return loading
}
