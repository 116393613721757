import { useAppDispatch, useAppSelector } from '../../store/store'
import './NetworkDropdown.css'
import { updateNetwork } from '../../store/network/NetworkActions'
import { Network } from '../../constants'
import { NetworkReducerState } from '../../store/network/NetworkReducer'
import { useEffect } from 'react'
import useUrlQuery from 'src/hooks/useUrlQuery'
import { useLocation, useNavigate } from 'react-router-dom'

const Networks = Object.values(Network)

const NetworkDropdown: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector
  const location = useLocation()
  const nav = useNavigate()

  const network: NetworkReducerState = selector(
    (state) => state.NetworkReducer
  )

  // get network from url params
  const query = useUrlQuery()
  useEffect(() => {
    // default to store first
    let curNetwork = network.network
    const urlNetwork = query.get('net')
    if (urlNetwork && Object.values(Network).includes(urlNetwork as Network)) {
      curNetwork = urlNetwork as Network
      if (network.network !== curNetwork) {
        dispatch(updateNetwork(curNetwork))
      }
    } else {
      nav(`${location.pathname}?net=${curNetwork}`)
    }

  }, [network, dispatch, query, nav, location.pathname])

  const handleChangeNetwork = (val: Network) => {
    try {
      dispatch(updateNetwork(val))
      nav(`${location.pathname}?net=${val}`)
    } catch (e: any) {
      console.error(e)
    }
  }

  return (
    <select
      className="network-select"
      value={network.network}
      onChange={(event) => handleChangeNetwork(event.target.value as Network)}
    >
      {
        Networks && Networks.map((n) => (
          <option key={n} value={n}>
            {n.toLowerCase()}
          </option>
        ))
      }
    </select>
  )
}

export default NetworkDropdown
