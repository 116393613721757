import * as React from "react";
const SvgTelegramWhite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <g clipPath="url(#telegram-white_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M38.3 47.456c.67.475 1.535.594 2.306.302a2.417 2.417 0 0 0 1.509-1.75c1.81-8.508 6.202-30.043 7.85-37.783a1.627 1.627 0 0 0-.542-1.58 1.668 1.668 0 0 0-1.66-.29C39.026 9.586 12.125 19.68 1.129 23.75A1.722 1.722 0 0 0 0 25.415c.025.737.52 1.377 1.235 1.591 4.932 1.475 11.405 3.527 11.405 3.527s3.025 9.136 4.602 13.782c.198.583.654 1.041 1.256 1.2.6.156 1.242-.009 1.69-.432l6.45-6.09s7.441 5.457 11.662 8.463ZM15.363 29.38l3.497 11.538.777-7.307s13.515-12.19 21.22-19.137a.578.578 0 0 0 .068-.786.592.592 0 0 0-.783-.133L15.362 29.38Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="telegram-white_svg__a">
        <path fill="#fff" d="M0 0h50v50H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTelegramWhite;
