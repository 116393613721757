import { useState } from "react";
import { HamburgerIcon } from "../../assets/components";
import { NavBarLinkItem } from "../../constants/NavigationConstants";
import "./HamburgerMenu.css";
import NavBarLink from "./NavBarLink";
import SearchBar from "./SearchBar";

type HamburgerMenuProps = {
  mainNavBarLinks: NavBarLinkItem[];
  networkSelectionLinks: NavBarLinkItem[];
  updateNavBarSelection: (selectedIndex: number, optionIndex?: number) => void
  updateNetworkSelection: (selectedIndex: number, optionIndex?: number) => void
};

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ mainNavBarLinks, networkSelectionLinks, updateNavBarSelection, updateNetworkSelection }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const toggleHamburgerDropdown = (): void => {
    setClicked((click) => !click);
  };
  return (
    <div>
      {!clicked && (
        <HamburgerIcon width="1.5em" height="1.5em" onClick={toggleHamburgerDropdown} />
      )}
      {clicked && (
        <HamburgerIcon width="1.5em" height="1.5em" onClick={toggleHamburgerDropdown} />
        )}
      {clicked && (
        <div className="dropdown-hamburger-menu row">
          <div className="column">
            <SearchBar />
            {mainNavBarLinks.map((navBarLink, index) => (
              <NavBarLink
                key={index}
                id={index}
                navBarLink={navBarLink}
                updateSelection={updateNavBarSelection}
                closeHamburgerMenu={toggleHamburgerDropdown}
              />
            ))
            }
            {networkSelectionLinks.map((navBarLink, index) => (
              <NavBarLink
                key={index}
                id={index}
                navBarLink={navBarLink}
                updateSelection={updateNetworkSelection}
                closeHamburgerMenu={toggleHamburgerDropdown}
              />
            ))}
          </div>

        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;