import React, { useState } from 'react'
import {
  DropdownOption,
  MAIN_NAV_BAR_LINKS,
  NavBarLinkItem,
  NETWORK_SELECTION,
} from '../../constants/NavigationConstants'
import Brand from './Brand'
import HamburgerMenu from './HamburgerMenu'
import './NavBar.css'
import NavBarLinks from './NavBarLinks'
import SearchBar from './SearchBar'
import NetworkDropdown from '../../components/NavBar/NetworkDropdown'

const NavBar: React.FC = () => {
  const [mainNavBarLinksState, setMainNavBarLinksState] =
    useState<NavBarLinkItem[]>(MAIN_NAV_BAR_LINKS)
  const [networkSelectionLinksState, setNetworkSelectionLinksState] =
    useState<NavBarLinkItem[]>(NETWORK_SELECTION)

  const unselectDropdownOptions = (dropdownOptions: DropdownOption[] = []) =>
    dropdownOptions?.map((option) => ({ ...option, selected: false }))

  const selectDropdownOptions = (
    dropdownOptions: DropdownOption[] = [],
    optionIndexSelected?: number
  ) => {
    if (
      optionIndexSelected !== undefined &&
      dropdownOptions[optionIndexSelected]
    ) {
      return dropdownOptions?.map((option, index) =>
        index === optionIndexSelected
          ? { ...option, selected: true }
          : { ...option, selected: false }
      )
    }
    return dropdownOptions
  }

  const updateNavBarState = (
    navbarLinks: NavBarLinkItem[],
    selectedIndex: number,
    optionIndex?: number
  ) => {
    return [
      ...navbarLinks.map((navbarLink, index) =>
        selectedIndex === index
          ? {
              ...navbarLink,
              selected: true,
              dropdownOptions: selectDropdownOptions(
                navbarLink.dropdownOptions,
                optionIndex
              ),
            }
          : {
              ...navbarLink,
              selected: false,
              dropdownOptions: unselectDropdownOptions(
                navbarLink.dropdownOptions
              ),
            }
      ),
    ]
  }

  const updateNavBarSelection = (
    selectedIndex: number,
    optionIndex?: number
  ) => {
    setMainNavBarLinksState(
      updateNavBarState(mainNavBarLinksState, selectedIndex, optionIndex)
    )
  }

  const updateNetworkSelection = (
    selectedIndex: number,
    optionIndex?: number
  ) => {
    setNetworkSelectionLinksState(
      updateNavBarState(networkSelectionLinksState, selectedIndex, optionIndex)
    )
  }

  return (
    <nav>
      <div className='container'>
        <div className='navbar-row'>
          <div className='brand-wrapper'><Brand/></div>
          <div className='nav-tab-wrapper'>
            <NavBarLinks
            mainNavBarLinks={mainNavBarLinksState}
            updateNavBarSelection={updateNavBarSelection}
          />
          </div>
          <div className='network-menu'><NetworkDropdown/></div>
          <div className='search-bar-wrapper'><SearchBar /></div>
          <div className='hamburger-menu-wrapper'>
            <HamburgerMenu 
              mainNavBarLinks={mainNavBarLinksState}
              networkSelectionLinks={networkSelectionLinksState}
              updateNavBarSelection={updateNavBarSelection}
              updateNetworkSelection={updateNetworkSelection}
            />
          </div>
        </div>
      </div>
    </nav>
  )
}
export default NavBar
