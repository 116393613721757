import { SortingRule } from 'react-table'
import {
  EventData,
  EventModel,
  FilterState,
} from '../../constants/DataModels'

import {
  RequestEvent,
  SetEvent,
  AddEventFilter,
  RemoveEventFilter,
  SetEventSort,
  RequestSearch,
  SetSearch,
} from './EventTypes'

export enum EventActionTypes {
  REQUEST_EVENT = 'REQUEST_EVENT',
  SET_EVENT = 'SET_EVENT',
  ADD_FILTER = 'ADD_EVENT_FILTER',
  REMOVE_FILTER = 'REMOVE_EVENT_FILTER',
  SET_SORT = 'SET_EVENT_SORT',
  REQUEST_SEARCH = 'REQUEST_SEARCH',
  SET_SEARCH = 'SET_SEARCH',
  SET_HEIGHT = 'SET_HEIGHT',
  REQUEST_HEIGHT = 'SET_HEIGHT',
}

export const requestEvent = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<EventData>
): RequestEvent => {
  return {
    type: EventActionTypes.REQUEST_EVENT,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setEvent = (
  data: EventModel
): SetEvent => {
  return {
    type: EventActionTypes.SET_EVENT,
    payload: data,
  }
}


export const addEventFilter = (
  column: string,
  row: string
): AddEventFilter => {
  return {
    type: EventActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeEventFilter = (
  column: string
): RemoveEventFilter => {
  return {
    type: EventActionTypes.REMOVE_FILTER,
    column,
  }
}


export const setEventSort = (
  id: string,
  desc: boolean
): SetEventSort => {
  return {
    type: EventActionTypes.SET_SORT,
    id,
    desc,
  }
}

export const requestSearch = (query: string): RequestSearch => {
  return {
    type: EventActionTypes.REQUEST_SEARCH,
    query,
  }
}

export const setSearch = (data: EventModel): SetSearch => {
  return {
    type: EventActionTypes.SET_SEARCH,
    payload: data,
  }
}
