import { SortingRule } from 'react-table'
import { TransferPayloadModel, FilterState, TransferPayloadData } from '../../constants/DataModels'

import {
  RequestTransferPayload,
  SetTransferPayload,
  AddTransferPayloadFilter,
  RemoveTransferPayloadFilter,
  SetTransferPayloadSort,
} from './TransferPayloadTypes'

export enum TransferPayloadActionTypes {
  REQUEST_TRANSFERPAYLOAD = 'REQUEST_TRANSFERPAYLOAD',
  SET_TRANSFERPAYLOAD = 'SET_TRANSFERPAYLOAD',
  ADD_FILTER = 'ADD_TRANSFERPAYLOAD_FILTER',
  REMOVE_FILTER = 'REMOVE_TRANSFERPAYLOAD_FILTER',
  SET_SORT = 'SET_TRANSFERPAYLOAD_SORT',
}

export const requestTransferPayload = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<TransferPayloadData>
): RequestTransferPayload => {
  return {
    type: TransferPayloadActionTypes.REQUEST_TRANSFERPAYLOAD,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setTransferPayload = (
  data: TransferPayloadModel
): SetTransferPayload => {
  return {
    type: TransferPayloadActionTypes.SET_TRANSFERPAYLOAD,
    payload: data,
  }
}


export const addTransferPayloadFilter = (
  column: string,
  row: string
): AddTransferPayloadFilter => {
  return {
    type: TransferPayloadActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeTransferPayloadFilter = (
  column: string
): RemoveTransferPayloadFilter => {
  return {
    type: TransferPayloadActionTypes.REMOVE_FILTER,
    column,
  }
}

export const setTransferPayloadSort = (id: string, desc: boolean): SetTransferPayloadSort => {
  return {
    type: TransferPayloadActionTypes.SET_SORT,
    id,
    desc
  }
}
