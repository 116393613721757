import React from 'react'
import { NavLink } from 'react-router-dom'
import { CheveronRight } from '../../assets/components'
import './Breadcrumb.css'

type props = {
  pages: string[]
}

const Breadcrumb: React.FC<props> = ({ pages }) => {
  return (
    <div className='breadcrumb-wrapper'>
      {pages.map((page, i, pages) =>
        i + 1 === pages.length ? (
          <div key={i.toString()} className='breadcrumb-current'>{page}</div>
        ) : (
          <NavLink key={i.toString()} to={`/${page}`} className='breadcrumb-prev'>
            <div className='breadcrumb-page'>{page}</div>
            <CheveronRight className='breadcrumb-cheveron'/>
          </NavLink>
        )
      )}
    </div>
  )
}

export default Breadcrumb
