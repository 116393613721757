import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
  FilterState,
  MonitorData,
  MonitorModel,
} from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { MonitorActionTypes, setMonitor } from '../monitor/MonitorActions'
import { RequestMonitor } from '../monitor/MonitorTypes'

//watchers
function* getMonitorWatcher() {
  yield takeLatest(MonitorActionTypes.REQUEST_MONITOR, getMonitorData)
}

//workers
function* getMonitorData(action: RequestMonitor) {
  yield runSagaTask('runMonitorSaga', function* () {
    const MonitorData = (yield call(
      getMonitorDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<MonitorModel>

    yield put(setMonitor(MonitorData.data))
  })
}

//API call
async function getMonitorDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<MonitorData>
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })

  const sort = sortState.desc ? '-' + sortState.id : sortState.id

  return await API.get<MonitorModel>(
    `/monitors?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )
}

export function* MonitorSaga() {
  yield all([fork(getMonitorWatcher)])
}
