import * as React from "react";
const SvgHamburgerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 56 40"
    {...props}
  >
    <path
      fill="#10296B"
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={5}
      d="M3 3h50M3 20h50M3 37h50"
    />
  </svg>
);
export default SvgHamburgerIcon;
