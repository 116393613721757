import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import { FilterState, QueueData, QueueModel } from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { QueueActionTypes, setQueue } from '../queue/QueueActions'
import { RequestQueue } from '../queue/QueueTypes'

//watchers
function* getQueueWatcher() {
  yield takeLatest(QueueActionTypes.REQUEST_QUEUE, getQueueData)
}

//workers
function* getQueueData(action: RequestQueue) {
  yield runSagaTask('runQueueSaga', function* () {
    const QueueData = (yield call(
      getQueueDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<QueueModel>

    yield put(setQueue(QueueData.data))
  })
}

//API call
async function getQueueDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<QueueData>
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })
  const sort =
  sortState.desc? '-' + sortState.id : sortState.id
  return await API.get<QueueModel>(
    `/queues?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )
}

export function* QueueSaga() {
  yield all([fork(getQueueWatcher)])
}
