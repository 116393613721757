import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { subHours } from 'date-fns'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import { BlockchainModel, OverviewModel } from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import {
  StatsActionTypes,
  setBlockchainData,
  setDayRelay,
  setOverviewData,
} from '../stats/StatsActions'
import { RequestBlockchainData, RequestOverviewData } from '../stats/StatsTypes'

//watchers
function* getBlockchains() {
  yield takeEvery(StatsActionTypes.REQUEST_BLOCKCHAIN_DATA, getBlockchainData)
}

function* getOverview() {
  yield takeEvery(StatsActionTypes.REQUEST_OVERVIEW_DATA, getOverviewData)
}

//workers
function* getBlockchainData(action: RequestBlockchainData) {
  yield runSagaTask('runStatsBlockchainSaga', function* () {
    const BlockchainData = (yield call(
      getBlockchainDataAPI
    )) as AxiosResponse<BlockchainModel>
    yield put(setBlockchainData(BlockchainData.data))
  })
}

function* getOverviewData(action: RequestOverviewData) {
  yield runSagaTask('runStatsOverviewSaga', function* () {
    const OverviewData = (yield call(
      getOverviewDataAPI
    )) as AxiosResponse<OverviewModel>
    yield put(setOverviewData(OverviewData.data))
  })

  yield runSagaTask('runDayTxSaga', function* () {
    // last 24h
    const currentDate = format(
      zonedTimeToUtc(subHours(new Date(), 24), 'UTC'),
      'yyyy-MM-dd HH:mm:ss'
    )
    const LatestOverviewData = (yield call(
      getDayTxDataAPI,
      currentDate
    )) as AxiosResponse<OverviewModel>
    yield put(setDayRelay(LatestOverviewData.data.relay_count))
  })
}

//API call
async function getBlockchainDataAPI() {
  return await API.get<BlockchainModel>(`/stats/supported_blockchains`)
}

async function getOverviewDataAPI() {
  return await API.get<OverviewModel>(`/stats/overview`)
}

async function getDayTxDataAPI(date: string) {
  return await API.get<OverviewModel>(`/stats/overview?start_time=${date}`)
}

export function* StatsSaga() {
  yield all([fork(getBlockchains), fork(getOverview)])
}
