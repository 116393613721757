import React from 'react'
import 'src/components/DetailsPage/sections/Transaction.css'
import { RegisterAssetData } from 'src/constants/DataModels'
import AttributeRow from 'src/components/shared/AttributeRow'
import AttributeTable from 'src/components/shared/AttributeTable'
import { ExplorerAssetLink } from 'src/components/shared/ExplorerAssetLink'

type CardProps = {
  payloadData?: RegisterAssetData
  sourceBlockchain: string
  destinationBlockchain: string
}

const PayloadRegisterAsset: React.FC<CardProps> = ({ payloadData, sourceBlockchain, destinationBlockchain }) => {
  if (!payloadData) {
    return <div>Not Available</div>
  }
  const {
    native_asset,
    native_asset_hash,
    carbon_asset,
    carbon_asset_hash,
  } = payloadData

  return (
    <AttributeTable>
      <AttributeRow name={'Native Asset'}>
        <ExplorerAssetLink blockchain={destinationBlockchain} assetIdOrAddress={native_asset} shouldTruncate={false}/>
      </AttributeRow>
      <AttributeRow name={'Native Asset Hash'}>{native_asset_hash}</AttributeRow>
      <AttributeRow name={'Carbon Asset'}>
        <ExplorerAssetLink blockchain={sourceBlockchain} assetIdOrAddress={carbon_asset} shouldTruncate={false}/>
      </AttributeRow>
      <AttributeRow name={'Carbon Asset Hash'}>{carbon_asset_hash}</AttributeRow>
    </AttributeTable>
  )
}

export default PayloadRegisterAsset
