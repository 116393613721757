import { Network } from '../../constants'
import { NetworkActionTypes } from './NetworkActionTypes'
import { SetNetwork, UpdateNetwork } from './NetworkTypes'

export const updateNetwork = (network: Network): UpdateNetwork => {
  return {
    type: NetworkActionTypes.UPDATE_NETWORK,
    network,
  }
}

export const setNetwork = (network: Network): SetNetwork => {
  return {
    type: NetworkActionTypes.SET_NETWORK,
    network,
  }
}
