import React from 'react'
import 'src/components/DetailsPage/sections/Transaction.css'
import { ExplorerTxHashLink } from 'src/components/shared/ExplorerTxHashLink'
import { TransactionData } from 'src/constants/DataModels'
import { formatChain, formatTime } from 'src/constants/ConversionUtils'
import { ExplorerAddressLink } from 'src/components/shared/ExplorerAddressLink'
import AttributeRow from 'src/components/shared/AttributeRow'
import AttributeTable from 'src/components/shared/AttributeTable'

type CardProps = {
  txInfo?: TransactionData
}

const Transaction: React.FC<CardProps> = ({ txInfo }) => {
  if (!txInfo) {
    return <div>Not Available</div>
  }
  const {
    blockchain,
    tx_fee,
    tx_hash,
    block_height,
    block_time,
    created_at,
    contract,
    relayed_by,
  } = txInfo

  return (
    <AttributeTable>
      <AttributeRow name={'Blockchain'}>{formatChain(blockchain)}</AttributeRow>
      <AttributeRow name={'Tx Hash'}>
        <ExplorerTxHashLink blockchain={blockchain} hash={tx_hash} />
      </AttributeRow>
      <AttributeRow name={'Tx Fee'}>{tx_fee}</AttributeRow>
      <AttributeRow name={'Block Height'}>{block_height}</AttributeRow>
      <AttributeRow name={'Block Time'}>{block_time}</AttributeRow>
      <AttributeRow name={'Created At'}>{formatTime(created_at)}</AttributeRow>
      <AttributeRow name={'Contract'}>
        {
          (blockchain === 'polynetwork' || blockchain === 'carbon' || blockchain === 'switcheo') ?
            contract :
            <ExplorerAddressLink address={contract} blockchain={blockchain} />
        }
      </AttributeRow>
      <AttributeRow name={'Sender'}>
        <ExplorerAddressLink address={relayed_by} blockchain={blockchain} />
      </AttributeRow>
    </AttributeTable>
  )
}

export default Transaction
