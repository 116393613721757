// for mainnet
import { Network } from 'src/constants/network'
import { combineURLs, strip0x, stripIbc } from 'src/constants/ConversionUtils'

export enum BlockExplorers {
  CarbonScan = 'https://scan.carbon.network/',
  Etherscan = 'https://etherscan.io/',
  Rinkeby = 'https://rinkeby.etherscan.io/',
  Bscscan = 'https://bscscan.com/',
  BscscanTestnet = 'https://testnet.bscscan.com/',
  Mantlescan = 'https://mantlescan.xyz/',
  MantlescanTestnet = 'https://sepolia.mantlescan.xyz/',
  Viewblock = 'https://viewblock.io/',
  NeoTube = 'https://neotube.io/',
  NeoTubeTestnet = 'https://testnet.neotube.io/',
  NeoTubeNeo3 = 'https://neo3.neotube.io/',
  NeoTubeNeo3Testnet = 'https://n3t4.neotube.io/',
  MintScan = 'https://www.mintscan.io/',
  MintScanTestnet = 'https://testnet.mintscan.io/',
  PolyNetwork = 'https://explorer.poly.network',
  Arbitrum = 'https://arbiscan.io/',
  ArbitrumTestnet = 'https://testnet.arbiscan.io/',
}

export enum EventNames {
  // ** PolyNetwork **

  // carbon
  MakeCosmosProof = 'make_from_cosmos_proof',
  VerifyCosmosProof = 'verify_to_cosmos_proof',

  // polynetwork bridge
  MakePolyProof = 'makeProof',
  SyncHeader = 'syncHeader',

  // evm, zil
  CrossChainEvent = 'CrossChainEvent',
  VerifyHeaderAndExecuteTxEvent = 'VerifyHeaderAndExecuteTxEvent',

  // neo3
  CrossChainLockEvent = 'CrossChainLockEvent',
  CrossChainUnlockEvent = 'CrossChainUnlockEvent',

  // ** IBC **

  SendPacket = 'send_packet',
  TimeoutPacket = 'timeout_packet',
  AcknowledgePacket = 'acknowledge_packet',
  ReceivePacket = 'recv_packet',

  // ** Carbon Bridge **

  // carbon
  NewPendingActionEvent = 'Switcheo.carbon.bridge.NewPendingActionEvent', // Carbon -> External
  ExpiredPendingActionEvent = 'Switcheo.carbon.bridge.ExpiredPendingActionEvent', // Carbon -> External
  BridgeSentEvent = 'Switcheo.carbon.bridge.BridgeSentEvent', // Carbon -> External
  BridgeAcknowledgedEvent = 'Switcheo.carbon.bridge.BridgeAcknowledgedEvent', // Carbon -> External
  BridgeRevertedEvent = 'Switcheo.carbon.bridge.BridgeRevertedEvent', // Carbon -> External
  AxelarCallContractEvent = 'Switcheo.carbon.bridge.AxelarCallContractEvent', // Carbon -> External
  BridgeReceivedEvent = 'Switcheo.carbon.bridge.BridgeReceivedEvent', // External -> Carbon
  AxelarGeneralMessageReceivedEvent = 'Switcheo.carbon.bridge.AxelarGeneralMessageReceivedEvent', // External -> Carbon

  // axelar bridge
  ContractCallSubmitted = 'axelar.axelarnet.v1beta1.ContractCallSubmitted', // Carbon -> External
  EVMEventConfirmed = 'axelar.evm.v1beta1.EVMEventConfirmed', // External -> Carbon

  // evm
  ContractCallApproved = 'ContractCallApproved', // Carbon -> External
  ContractCallExecuted = 'ContractCallExecuted', // Carbon -> External
  ContractCall = 'ContractCall', // External -> Carbon
}

// to specify whether its inbound to carbon or outbound
export enum FlowType {
  In = 'in', // into carbon
  Out = 'out' // out of carbon
}

export enum ConfirmationStatus {
  unconfirmed = 'unconfirmed',
  confirming = 'confirming',
  confirmed = 'confirmed',
  error = 'error', // only for ui purposes
}

export enum ToCarbonPayloadType {
  Deposit = '0',
  DepositAndExecute = '1',
  ExecuteCarbon = '2',
  ConfirmationCallback = '3',
}

export enum FromCarbonPayloadType {
  Withdraw = '0',
  ExecuteGateway = '1',
  WithdrawAndExecute = '2',
  RegisterToken = '3',
  DeregisterToken = '4',
}

export function getTransactionURL(blockchain: string, transactionHash: string, network = Network.MainNet) {
  return formExplorerURL(blockchain, 'transaction', transactionHash, network)
}

export function getAddressURL(blockchain: string, address: string, network = Network.MainNet) {
  return formExplorerURL(blockchain, 'address', address, network)
}

export function getAssetURL(blockchain: string, asset: string, network = Network.MainNet) {
  return formExplorerURL(blockchain, 'asset', asset, network)
}

function formExplorerURL(blockchain: string, queryType: 'address' | 'transaction' | 'asset', idStr: string, network = Network.MainNet) {
  let baseUrl = ''
  let addrPath = 'address'
  let txPath = 'transaction'
  let assetPath = 'address'
  let formattedIdStr = idStr
  let queryStr = ''
  let shouldStrip0x = false
  switch (blockchain) {
    case 'carbon':
    case 'switcheo': {
      const net = network === Network.MainNet ? 'main' : 'dev'
      baseUrl = BlockExplorers.CarbonScan
      addrPath = 'account'
      assetPath = 'token'
      queryStr = `net=${net}`
      shouldStrip0x = true
      break
    }
    case 'polynetwork': {
      baseUrl = network === Network.MainNet ? BlockExplorers.PolyNetwork : ''
      txPath = 'tx'
      shouldStrip0x = true
      break
    }
    case 'eth': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.Etherscan : BlockExplorers.Rinkeby
      txPath = 'tx'
      break
    }
    case 'arbitrum': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.Arbitrum : BlockExplorers.ArbitrumTestnet
      txPath = 'tx'
      break
    }
    case 'bsc': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.Bscscan : BlockExplorers.BscscanTestnet
      txPath = 'tx'
      break
    }
    case 'mantle': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.Mantlescan : BlockExplorers.MantlescanTestnet
      txPath = 'tx'
      break
    }
    case 'zil': {
      baseUrl = `${BlockExplorers.Viewblock}zilliqa`
      txPath = 'tx'
      queryStr = `network=${network}`
      shouldStrip0x = true
      break
    }
    case 'neo': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.NeoTube : BlockExplorers.NeoTubeTestnet
      assetPath = 'tokens/nep17'
      break
    }
    case 'neo3': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.NeoTubeNeo3 : BlockExplorers.NeoTubeNeo3Testnet
      assetPath = 'tokens/nep17'
      break
    }
    case 'cosmoshub': {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.MintScan : BlockExplorers.MintScanTestnet
      baseUrl = `${baseUrl}cosmos`
      txPath = 'txs'
      addrPath = 'account'
      assetPath = 'assets'
      shouldStrip0x = true
      formattedIdStr = stripIbc(formattedIdStr)
      break
    }
    // Assume IBC
    default: {
      baseUrl = network === Network.MainNet ?
        BlockExplorers.MintScan : BlockExplorers.MintScanTestnet
      baseUrl = `${baseUrl}${blockchain}`
      txPath = 'txs'
      addrPath = 'account'
      assetPath = 'assets'
      shouldStrip0x = true
      formattedIdStr = stripIbc(formattedIdStr)
    }
  }
  if (!baseUrl) {
    return ''
  }
  if (shouldStrip0x) {
    formattedIdStr = strip0x(formattedIdStr)
  }
  formattedIdStr = encodeURIComponent(formattedIdStr)

  // form path
  let path: string
  switch (queryType) {
    case 'address':
      path = `${addrPath}/${formattedIdStr}`
      break
    case 'transaction':
      path = `${txPath}/${formattedIdStr}`
      break
    case 'asset':
      path = `${assetPath}/${formattedIdStr}`
  }
  if (queryStr) {
    path = `${path}?${queryStr}`
  }
  return combineURLs(baseUrl, path)
}
