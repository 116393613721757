import * as React from "react";
const SvgDiscordWhite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 53 53"
    {...props}
  >
    <path
      fill="#fff"
      d="M41.83 12.27a35.991 35.991 0 0 0-9.111-2.865c-.393.71-.85 1.665-1.169 2.423a33.513 33.513 0 0 0-10.098 0 25.57 25.57 0 0 0-1.182-2.423 35.939 35.939 0 0 0-9.118 2.871c-5.766 8.714-7.33 17.212-6.548 25.588a36.425 36.425 0 0 0 11.177 5.726 27.933 27.933 0 0 0 2.394-3.942 23.605 23.605 0 0 1-3.77-1.835c.316-.234.625-.479.923-.73 7.268 3.398 15.163 3.398 22.344 0 .303.251.612.496.923.73a23.402 23.402 0 0 1-3.776 1.838 27.65 27.65 0 0 0 2.394 3.941 36.349 36.349 0 0 0 11.183-5.73c.916-9.708-1.566-18.128-6.566-25.593ZM19.164 32.712c-2.182 0-3.97-2.036-3.97-4.516s1.75-4.52 3.97-4.52 4.008 2.036 3.97 4.52c.003 2.48-1.75 4.516-3.97 4.516Zm14.672 0c-2.182 0-3.97-2.036-3.97-4.516s1.75-4.52 3.97-4.52 4.008 2.036 3.97 4.52c0 2.48-1.75 4.516-3.97 4.516Z"
    />
  </svg>
);
export default SvgDiscordWhite;
