/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { SortingRule, usePagination, useSortBy } from 'react-table'
import { FilterData, FilterState, RelayData } from '../../constants/DataModels'
import Table, { TableConfig } from '../Tables/Table'
import { RELAY_COLUMNS } from '../Tables/TableColumns'

import { addRelayFilter, removeRelayFilter, requestRelay } from '../../store/relays/RelayActions'

import { RelayState } from '../../store/relays/RelayTypes'

import { formatBridge, formatChain, formatRelayStatus } from 'src/constants/ConversionUtils'
import { DEFAULT_FILTER_STATE, DEFAULT_OFFSET, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_SORT_STATE } from 'src/constants/PageConstants'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { requestBlockchainData } from '../../store/stats/StatsActions'
import { StatsState } from '../../store/stats/StatsTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import './RelayPage.css'

const RelayPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector

  // state from relays store
  const relays: RelayState = selector((state) => state.RelayReducer)
  const [relayError, setRelayError] = useState<Error | null>(null)
  const relayLoading: boolean = useTaskSubscriber('runRelaySaga')

  // with pagination and filters enabled
  const { pagination, data } = relays.data
  const filterState = relays.filters
  const [pageCount, setPageCount] = useState(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))

  // state from supported_blockchains
  const [blockchainOptions, setBlockchainOptions] = useState([''])
  const [bridgeOptions, setBridgeOptions] = useState([''])
  const blockchains: StatsState = selector((state) => state.StatsReducer)

  const network = selector((state) => state.NetworkReducer.network)

  // format blockchains and bridges for filter options
  const formatFilterOptions = () => {
    const blockchainStats = blockchains.blockchains.data
    const formattedBlockchains = Array.from(new Set(blockchainStats.filter((row) => row.blockchain !== 'polynetwork').map((row) => row.blockchain))).sort()
    const formattedBridges = Array.from(new Set(blockchainStats.filter((row) => row.bridge !== 'all').map((row) => row.bridge)))
    setBridgeOptions(formattedBridges)
    setBlockchainOptions(formattedBlockchains)
  }

  // get table data for relays table
  const handleDataRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<RelayData>[]) => {
    try {
      dispatch(requestRelay(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setRelayError(e)
    }
  }

  // handle remove filter
  const handleRemoveFilter = useCallback((column: string) => {
    dispatch(removeRelayFilter(column))
  }, [])

  // handle add filter
  const handleAddFilter = useCallback((column: string, row: string) => {
    dispatch(addRelayFilter(column, row))
  }, [])

  // initial render to set states
  useEffect(() => {
    dispatch(requestBlockchainData())
    handleDataRequest(DEFAULT_OFFSET, DEFAULT_PAGE_SIZE, DEFAULT_FILTER_STATE, DEFAULT_SORT_STATE)
  }, [network])

  // set initial page count and filter options state
  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))
    formatFilterOptions()
  }, [pagination.total])

  // filter options
  const filterOptions: FilterData[] = [
    {
      name: 'Bridge',
      column: 'bridge',
      rows: bridgeOptions,
      rowFormatter: formatBridge,
    },
    {
      name: 'Source',
      column: 'source_blockchain',
      rows: blockchainOptions,
      rowFormatter: formatChain,
    },
    {
      name: 'Destination',
      column: 'destination_blockchain',
      rows: blockchainOptions,
      rowFormatter: formatChain,
    },
    {
      name: 'Status',
      column: 'status',
      rows: ['in_transit', 'completed', 'refunded'],
      rowFormatter: formatRelayStatus,
    },
    {
      name: 'Search Tx/Addr',
      column: 'search_term',
    },
  ]

  const mobileFilterOptions: FilterData[] = [
    {
      name: 'Bridge',
      column: 'bridge',
      rows: bridgeOptions,
      rowFormatter: formatBridge,
    },
    {
      name: 'Source',
      column: 'source_blockchain',
      rows: blockchainOptions,
      rowFormatter: formatChain,
    },
    {
      name: 'Destination',
      column: 'destination_blockchain',
      rows: blockchainOptions,
      rowFormatter: formatChain,
    },
    {
      name: 'Status',
      column: 'status',
      rows: ['in_transit', 'completed', 'refunded'],
      rowFormatter: formatRelayStatus,
    },
    {
      name: 'Search Tx/Addr',
      column: 'search_term',
    },
  ]

  // table config
  const tableConfig: TableConfig<RelayData> = {
    options: {
      columns: RELAY_COLUMNS,
      data: data,
      initialState: {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: DEFAULT_SORT_STATE,
      },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  //pagination enabled
  const paginationConfig = {
    paginationData: pagination,
    onPageCountChange: setPageCount,
  }

  //filters enabled
  const filterConfig = {
    filterOptions: filterOptions,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
    filterState: filterState,
    dateRangeFilter: true,
  }

  return (
    <div>
      <div className="container main-wrapper">
        <Breadcrumb pages={['home', 'relays']} />
        <Table tableConfig={tableConfig} isLoading={relayLoading} error={relayError} name="relays" onDataRequest={handleDataRequest} paginationConfig={paginationConfig} filterConfig={filterConfig} />
      </div>
    </div>
  )
}

export default RelayPage
