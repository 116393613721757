import { PaginationData} from '../../constants/DataModels'
import { MonitorTypes, MonitorState } from './MonitorTypes'
import { MonitorActionTypes } from './MonitorActions'

const initialState: MonitorState = {
    data: {
      data: [],
      pagination: {} as PaginationData,
    },
    filters: {
    },
    sort: {
      id: '',
      desc: false
    }
  }

//TODO: Add fail action
const MonitorReducer = function (
    state = initialState,
    action: MonitorTypes
  ) {
    switch (action.type) {
      case MonitorActionTypes.SET_MONITOR: {
        return {
          ...state,
          data: action.payload,
        }
      }
  
      case MonitorActionTypes.ADD_FILTER: {
        let newFilterState = { ...state.filters }
        newFilterState[action.column] = action.row
        return {
          ...state,
          filters: newFilterState,
        }
      }
  
      case MonitorActionTypes.REMOVE_FILTER: {
        let newFilterState = { ...state.filters }
        delete newFilterState[action.column]
        return {
          ...state,
          filters: newFilterState,
        }
      }

      case MonitorActionTypes.SET_SORT: {
        return {
          ...state,
          sort: {
            id: action.id,
            desc: action.desc
          },
        }
      }

  
      default: {
        return state
      }
    }
  }
  
  export default MonitorReducer