import { SortingRule } from "react-table"
import { RelayerModel, FilterState, RelayerData } from "src/constants/DataModels"

import {
    RequestRelayer,
    SetRelayer,
    AddRelayerFilter,
    RemoveRelayerFilter,
    SetRelayerSort
} from "./RelayerTypes"
  
export enum RelayerActionTypes {
    REQUEST_RELAYER = 'REQUEST_RELAYER',
    SET_RELAYER = 'SET_RELAYER',
    ADD_FILTER = 'ADD_RELAYER_FILTER',
    REMOVE_FILTER = 'REMOVE_RELAYER_FILTER',
    SET_SORT = 'SET_RELAYER_SORT',
}
  
export const requestRelayer = (
    offset: number,
    limit: number,
    filterState: FilterState,
    sortState: SortingRule<RelayerData>
): RequestRelayer => {
    return {
        type: RelayerActionTypes.REQUEST_RELAYER,
        offset,
        limit,
        filterState,
        sortState,
    }
}
  
export const setRelayer = (data: RelayerModel): SetRelayer => {
    return {
        type: RelayerActionTypes.SET_RELAYER,
        payload: data,
    }
}
  
export const addRelayerFilter = (
    column: string,
    row: string
): AddRelayerFilter => {
    return {
        type: RelayerActionTypes.ADD_FILTER,
        column,
        row,
    }
}
  
export const removeRelayerFilter = (column: string): RemoveRelayerFilter => {
    return {
        type: RelayerActionTypes.REMOVE_FILTER,
        column,
    }
}
  
export const setRelayerSort = (id: string, desc: boolean): SetRelayerSort => {
    return {
        type: RelayerActionTypes.SET_SORT,
        id,
        desc
    }
}
  