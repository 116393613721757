import React from 'react'
import './Summary.css'
import { RelayDetailModel } from 'src/constants/DataModels'
import StatusTag from 'src/components/shared/StatusTag'
import { formatBridge, formatChain, formatPayloadType, formatTime } from 'src/constants/ConversionUtils'
import AttributeValue from 'src/components/DetailsPage/AttributeValue'
import { ExplorerTxHashLink } from 'src/components/shared/ExplorerTxHashLink'
import { FaArrowRight } from 'react-icons/fa'
import { FlowType } from 'src/constants/BlockchainConstants'
import Timeline from './Timeline'

type Props = {
  title: string
  data: RelayDetailModel
}

const Summary: React.FC<Props> = ({ title, data }) => {
  const {
    created_at,
    payload_type,
    bridge,
    bridging_tx_hash,
    source_blockchain,
    source_tx_hash,
    destination_blockchain,
    destination_tx_hash,
    status,
    flow_type,
    events,
  } = data
  const isIBCRefunded = bridge === 'ibc' && status === 'refunded'
  const type = formatPayloadType(bridge, flow_type as FlowType, payload_type)
  const isOldBridge = bridge === 'ibc' || bridge === 'polynetwork'
  return (
    <div>
      <h4 className="section-title">{title}</h4>
      <div className="summary-content">
        <div className="info">
          <AttributeValue attr={'Bridge'}>{formatBridge(bridge)}</AttributeValue>
          <AttributeValue attr={'Created At'}>{formatTime(created_at)}</AttributeValue>
          <AttributeValue attr={'Type'}>{type}</AttributeValue>
          <AttributeValue attr={'Status'}>
            <StatusTag status={status} />
          </AttributeValue>
        </div>
        <AttributeValue attr={'Transit'}>
          <div className="transit">{formatChain(source_blockchain)}
            <FaArrowRight /> {formatChain(destination_blockchain)}</div>
          {
            isOldBridge ?

              <div className={'transit-table'}>
                <table>
                  <tbody>
                  <tr>
                    <td>Source</td>
                    <td>
                      <ExplorerTxHashLink blockchain={source_blockchain} hash={source_tx_hash} />
                    </td>
                  </tr>
                  {
                    bridge !== 'ibc' && (
                      <tr>
                        <td>Bridge</td>
                        <td>
                          {
                            bridging_tx_hash ?
                              <ExplorerTxHashLink blockchain={bridge} hash={bridging_tx_hash} /> :
                              'Not available'
                          }
                        </td>
                      </tr>
                    )
                  }
                  <tr>
                    <td>Destination</td>
                    <td>
                      {
                        destination_tx_hash ?
                          <ExplorerTxHashLink blockchain={
                            isIBCRefunded ? source_blockchain : destination_blockchain}
                                              hash={destination_tx_hash}
                          /> :
                          'Not available'
                      }
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              :
              <Timeline events={events} relay={data} />
          }
        </AttributeValue>

      </div>
    </div>
  )
}

export default Summary
