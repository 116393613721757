import { PaginationData } from '../../constants/DataModels'
import { QueueTypes, QueueState } from './QueueTypes'
import { QueueActionTypes } from './QueueActions'

const initialState: QueueState = {
  data: {
    data: [],
    pagination: {} as PaginationData,
  },
  filters: {},
  sort: { id: '', desc: false },
}

//TODO: Add fail action
const QueueReducer = function (state = initialState, action: QueueTypes) {
  switch (action.type) {
    case QueueActionTypes.SET_QUEUE: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case QueueActionTypes.ADD_FILTER: {
      let newFilterState = { ...state.filters }
      newFilterState[action.column] = action.row
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case QueueActionTypes.REMOVE_FILTER: {
      let newFilterState = { ...state.filters }
      delete newFilterState[action.column]
      return {
        ...state,
        filters: newFilterState,
      }
    }
    case QueueActionTypes.SET_SORT: {
      return {
        ...state,
        sort: {
          id: action.id,
          desc: action.desc
        },
      }
    }
    default: {
      return state
    }
  }
}

export default QueueReducer
