import { PaginationData, RelayDetailModel } from '../../constants/DataModels'
import { RelayTypes, RelayState, BridgingRelayState } from './RelayTypes'
import { RelayActionTypes } from './RelayActions'
import {
  DEFAULT_END_DATE_FORMAT,
  DEFAULT_START_DATE_FORMAT,
} from '../../components/Tables/DateRangeFilter'

const initialState: RelayState = {
  data: {
    data: [],
    pagination: {} as PaginationData,
  },
  filters: {
    start_time: DEFAULT_START_DATE_FORMAT,
    end_time: DEFAULT_END_DATE_FORMAT,
  },
  txDetail: {} as RelayDetailModel,
  sort: {
    id: '',
    desc: false,
  },
  search: {
    data: [],
    pagination: {} as PaginationData,
  },
}

const bridgingState: BridgingRelayState = {
  txDetail: {} as RelayDetailModel,
}

//TODO: Add fail action
const RelayReducer = function (
  state = initialState,
  action: RelayTypes
) {
  switch (action.type) {
    case RelayActionTypes.SET_RELAY: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case RelayActionTypes.ADD_FILTER: {
      let newFilterState = { ...state.filters }
      newFilterState[action.column] = action.row
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case RelayActionTypes.REMOVE_FILTER: {
      let newFilterState = { ...state.filters }
      delete newFilterState[action.column]
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case RelayActionTypes.SET_TX: {
      return {
        ...state,
        txDetail: action.payload,
      }
    }

    case RelayActionTypes.SET_SORT: {
      return {
        ...state,
        sort: {
          id: action.id,
          desc: action.desc,
        },
      }
    }

    case RelayActionTypes.SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export const BridgingRelayReducer = function (
  state = bridgingState,
  action: RelayTypes
) {
  switch (action.type) {
    case RelayActionTypes.SET_BRIDGE_TX: {
      return {
        ...state,
        txDetail: action.payload,
      }
    }

    default: {
      return state
    }
  }
}


export default RelayReducer
