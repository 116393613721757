import { SortingRule } from 'react-table'
import { QueueModel, FilterState, QueueData } from '../../constants/DataModels'

import {
  RequestQueue,
  SetQueue,
  AddQueueFilter,
  RemoveQueueFilter,
  SetQueueSort,
} from './QueueTypes'

export enum QueueActionTypes {
  REQUEST_QUEUE = 'REQUEST_QUEUE',
  SET_QUEUE = 'SET_QUEUE',
  ADD_FILTER = 'ADD_QUEUE_FILTER',
  REMOVE_FILTER = 'REMOVE_QUEUE_FILTER',
  SET_SORT = 'SET_QUEUE_SORT'
}

export const requestQueue = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<QueueData>
): RequestQueue => {
  return {
    type: QueueActionTypes.REQUEST_QUEUE,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setQueue = (data: QueueModel): SetQueue => {
  return {
    type: QueueActionTypes.SET_QUEUE,
    payload: data,
  }
}

export const addQueueFilter = (
  column: string,
  row: string
): AddQueueFilter => {
  return {
    type: QueueActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeQueueFilter = (column: string): RemoveQueueFilter => {
  return {
    type: QueueActionTypes.REMOVE_FILTER,
    column,
  }
}

export const setQueueSort = (id: string, desc: boolean): SetQueueSort => {
  return {
    type: QueueActionTypes.SET_SORT,
    id,
    desc
  }
}
