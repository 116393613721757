import { CellProps, Column } from 'react-table'
import {
  formatBridge,
  formatSnakeCaseToTitleCase,
  formatUTCEpochTime,
  formatTime,
  maskHash, formatPayloadType,
} from '../../constants/ConversionUtils'
import {
  QueueData,
  RegisterAssetData,
  RelayData,
  MonitorData,
  TransferPayloadData,
  EventData,
  RelayerData,
} from '../../constants/DataModels'
import { DetailsColumn } from './DetailsColumn'
import { SortableHeader } from './SortableHeader'
import StatusTag from 'src/components/shared/StatusTag'
import { BlockchainColumn } from './BlockchainColumn'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ExplorerAddressLink } from 'src/components/shared/ExplorerAddressLink'
import { ExplorerTxHashLink } from 'src/components/shared/ExplorerTxHashLink'
import { ExplorerAssetLink } from 'src/components/shared/ExplorerAssetLink'
import { FlowType } from 'src/constants/BlockchainConstants'

dayjs.extend(relativeTime)

export const RELAY_COLUMNS: Array<Column<RelayData>> = [
  {
    id: 'created_at',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Date/Time"
          headerId="created_at"
        />
      )
    },
    accessor: (row) => {
      return <div className="date-column">{formatTime(row.created_at)}</div>
    },
  },
  {
    id: 'bridge',
    Header: 'Bridge',
    accessor: 'bridge',
    Cell: (props: CellProps<RelayData, string>) => {
      return <div>{formatBridge(props.cell.row.values.bridge)}</div>
    },
  },
  {
    id: 'flow_type',
    Header: 'Flow',
    accessor: 'flow_type',
    Cell: (props: CellProps<RelayData, string>) => {
      const { flow_type } = props.cell.row.values
      return (
        <div className="capitalize">{flow_type}</div>
      )
    },
  },
  {
    id: 'payload_type',
    Header: 'Type',
    accessor: 'payload_type',
    Cell: (props: CellProps<RelayData, string>) => {
      const { bridge, flow_type, payload_type } = props.cell.row.values
      return (
        <div>{formatPayloadType(bridge, flow_type as FlowType, payload_type)}</div>
      )
    },
  },
  {
    id: 'source_blockchain',
    Header: 'Source',
    accessor: 'source_blockchain',
    Cell: (props: CellProps<RelayData, string>) => {
      return BlockchainColumn({
        blockchain: props.cell.row.values.source_blockchain,
      })
    },
  },
  {
    id: 'destination_blockchain',
    Header: 'Destination',
    accessor: 'destination_blockchain',
    Cell: (props: CellProps<RelayData, string>) => {
      return BlockchainColumn({
        blockchain: props.cell.row.values.destination_blockchain,
      })
    },
  },
  {
    id: 'source_tx_hash',
    Header: 'Source Tx',
    accessor: (row) => {
      return row.source_tx_hash ? row.source_tx_hash : ''
    },
    Cell: (props: CellProps<RelayData, string>) => {
      return ExplorerTxHashLink({
        hash: props.cell.row.values.source_tx_hash,
        blockchain: props.cell.row.values.source_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'bridging_tx_hash',
    Header: 'Bridge Tx',
    accessor: (row) => {
      return row.bridging_tx_hash ? row.bridging_tx_hash : ''
    },
    Cell: (props: CellProps<RelayData, string>) => {
      return ExplorerTxHashLink({
        hash: props.cell.row.values.bridging_tx_hash,
        blockchain: props.cell.row.values.bridge,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'destination_tx_hash',
    Header: 'Destination Tx',
    accessor: (row) => {
      return row.destination_tx_hash ? row.destination_tx_hash : ''
    },
    Cell: (props: CellProps<RelayData, string>) => {
      const {
        destination_tx_hash,
        source_blockchain,
        destination_blockchain,
        status,
        bridge,
      } = props.cell.row.values
      const isIBCRefunded = bridge === 'ibc' && status === 'refunded'
      return ExplorerTxHashLink({
        hash: destination_tx_hash,
        blockchain: isIBCRefunded ? source_blockchain : destination_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: (row) => {
      return row.status
    },
    Cell: (props: CellProps<RelayData, string>) => {
      return StatusTag({ status: props.cell.row.values.status })
    },
  },
  {
    id: 'Details',
    accessor: (row) => {
      return row.id
    },
    Cell: (props: CellProps<RelayData, string>) => {
      return DetailsColumn(props.cell.row.values.Details)
    },
  },
]

export const EVENT_COLUMNS: Array<Column<EventData>> = [
  {
    id: 'created_at',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Date/Time"
          headerId="created_at"
        />
      )
    },
    accessor: (row) => {
      return row.block_time
        ? formatUTCEpochTime(Number(row.block_time))
        : formatTime(row.created_at)
    },
  },
  {
    id: 'blockchain',
    Header: 'Blockchain',
    accessor: 'blockchain',
    Cell: (props: CellProps<EventData, string>) => {
      return BlockchainColumn({
        blockchain: props.cell.row.values.blockchain,
      })
    },
  },
  {
    id: 'block_height',
    Header: 'Height',
    accessor: 'block_height',
    Cell: (props: CellProps<EventData, number>) => {
      return <div>{props.cell.row.values.block_height}</div>
    },
  },
  {
    id: 'tx_hash',
    Header: 'Transaction Hash',
    accessor: (row) => {
      return row.tx_hash ? row.tx_hash : ''
    },
    Cell: (props: CellProps<EventData, string>) => {
      return ExplorerTxHashLink({
        hash: props.cell.row.values.tx_hash,
        blockchain: props.cell.row.values.blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'sender',
    Header: 'Sender',
    accessor: (row) => {
      return row.sender ? row.sender : ''
    },
    Cell: (props: CellProps<EventData, string>) => {
      return ExplorerAddressLink({
        address: props.cell.row.values.sender,
        blockchain: props.cell.row.values.blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'name',
    Header: 'Event Name',
    accessor: 'name',
    Cell: (props: CellProps<EventData, string>) => {
      return <div>{props.cell.row.values.name}</div>
    },
  },
  {
    id: 'processing_status',
    Header: 'Processing Status',
    accessor: 'processing_status',
    Cell: (props: CellProps<EventData, string>) => {
      return <div>{props.cell.row.values.processing_status}</div>
    },
  },
  {
    id: 'link_status',
    Header: 'Link Status',
    accessor: 'link_status',
    Cell: (props: CellProps<EventData, string>) => {
      return <div>{props.cell.row.values.link_status}</div>
    },
  },
  {
    id: 'Details',
    accessor: (row) => {
      return row.relay_id
    },
    Cell: (props: CellProps<EventData, string>) => {
      return DetailsColumn(props.cell.row.values.Details)
    },
  }
]

export const TRANSFER_PAYLOAD_COLUMNS: Array<Column<TransferPayloadData>> = [
  {
    id: 'created_at',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Date/Time"
          headerId="created_at"
        />
      )
    },
    accessor: (row) => {
      return <div className="date-column">{formatTime(row.created_at)}</div>
    },
  },
  {
    id: 'bridging_blockchain',
    Header: 'Bridge',
    accessor: (row) => {
      return row.bridging_blockchain
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return (
        <div>{formatBridge(props.cell.row.values.bridging_blockchain)}</div>
      )
    },
  },
  {
    id: 'source_blockchain',
    Header: 'From',
    accessor: (row) => {
      return row.source_blockchain
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return BlockchainColumn({
        blockchain: props.cell.row.values.source_blockchain,
      })
    },
  },
  {
    id: 'destination_blockchain',
    Header: 'To',
    accessor: (row) => {
      return row.destination_blockchain
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return BlockchainColumn({
        blockchain: props.cell.row.values.destination_blockchain,
      })
    },
  },
  {
    id: 'from_asset',
    Header: 'From Asset',
    accessor: (row) => {
      return row.from_asset ? row.from_asset : ''
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return ExplorerAssetLink({
        assetIdOrAddress: props.cell.row.values.from_asset,
        blockchain: props.cell.row.values.source_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'from_asset_hash',
    Header: 'From Asset Hash',
    accessor: (row) => {
      return row.from_asset_hash ? maskHash(row.from_asset_hash, 6) : ''
    },
  },
  {
    id: 'to_asset',
    Header: 'To Asset',
    accessor: (row) => {
      return row.to_asset ? row.to_asset : '-'
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return ExplorerAssetLink({
        assetIdOrAddress: props.cell.row.values.to_asset,
        blockchain: props.cell.row.values.destination_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'to_asset_hash',
    Header: 'To Asset Hash',
    accessor: (row) => {
      return row.to_asset_hash ? maskHash(row.to_asset_hash, 6) : '-'
    },
  },
  {
    id: 'from_address',
    Header: 'From Address',
    accessor: (row) => {
      return row.from_address ? row.from_address : '-'
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return ExplorerAddressLink({
        address: props.cell.row.values.from_address,
        blockchain: props.cell.row.values.source_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    id: 'to_address',
    Header: 'To Address',
    accessor: (row) => {
      return row.to_address ? row.to_address : '-'
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return ExplorerAddressLink({
        address: props.cell.row.values.to_address,
        blockchain: props.cell.row.values.destination_blockchain,
        shouldTruncate: true,
      })
    },
  },
  {
    Header: 'Amount',
    accessor: (row) => {
      return row.amount ? row.amount : '-'
    },
  },
  {
    Header: 'Type',
    accessor: (row) => {
      return formatSnakeCaseToTitleCase(row.transfer_payload_type)
    },
  },
  {
    id: 'Details',
    accessor: (row) => {
      return row.relay_id
    },
    Cell: (props: CellProps<TransferPayloadData, string>) => {
      return DetailsColumn(props.cell.row.values.Details)
    },
  },
]

export const REGISTER_ASSET_COLUMNS: Array<Column<RegisterAssetData>> = [
  {
    id: 'created_at',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Date/Time"
          headerId="created_at"
        />
      )
    },
    accessor: (row) => {
      return <div className="date-column">{formatTime(row.created_at)}</div>
    },
  },
  {
    Header: 'Native Asset',
    accessor: 'native_asset',
  },
  {
    Header: 'Native Asset (Hash)',
    accessor: (row) => {
      return row.native_asset_hash ? maskHash(row.native_asset_hash, 6) : '-'
    },
  },
  {
    Header: 'Carbon Asset',
    accessor: 'carbon_asset',
    Cell: (props: CellProps<RegisterAssetData, string>) => {
      return ExplorerAssetLink({
        assetIdOrAddress: props.cell.row.values.carbon_asset,
        blockchain: 'carbon',
        shouldTruncate: true,
      })
    },
  },
  {
    Header: 'Carbon Asset (Hash)',
    accessor: (row) => {
      return row.carbon_asset_hash ? maskHash(row.carbon_asset_hash, 6) : '-'
    },
  },
  {
    id: 'Details',
    accessor: (row) => {
      return row.relay_id
    },
    Cell: (props: CellProps<RegisterAssetData, string>) => {
      return DetailsColumn(props.cell.row.values.Details)
    },
  },
]

export const MONITOR_COLUMNS: Array<Column<MonitorData>> = [
  {
    id: 'bridge',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Bridge"
          headerId="bridge"
        />
      )
    },
    accessor: 'bridge',
  },
  {
    id: 'blockchain',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Blockchain"
          headerId="blockchain"
        />
      )
    },
    accessor: 'blockchain',
  },
  {
    id: 'latest_height',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Latest Block Height"
          headerId="latest_height"
        />
      )
    },
    accessor: (row) => {
      return row.latest_height ? row.latest_height : '-'
    },
  },
  {
    id: 'polled_height',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Polled Tx Height"
          headerId="polled_height"
        />
      )
    },
    accessor: (row) => {
      return row.polled_height ? (
        <span>
          {' '}
          {row.polled_height} <br /> {row.blocks_left} blocks left
        </span>
      ) : (
        '-'
      )
    },
  },
  {
    id: 'last_saved_time',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Last Polled Time"
          headerId="last_saved_time"
        />
      )
    },
    accessor: (row) => {
      return row.last_saved_time
        ? dayjs(row.last_saved_time * 1000).fromNow()
        : '-'
    },
  },
  {
    id: 'is_active',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Active"
          headerId="is_active"
        />
      )
    },
    accessor: (row) => {
      return row.is_active ? 'Active' : 'Inactive'
    },
  },
  {
    id: 'total_transactions',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Total Tx"
          headerId="total_transactions"
        />
      )
    },
    accessor: 'total_transactions',
  },
  {
    id: 'total_unlinked',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Unlinked Tx"
          headerId="total_unlinked"
        />
      )
    },
    accessor: 'total_unlinked',
  },
  {
    id: 'total_in_transit_to_carbon',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="In Transit To Carbon"
          headerId="total_in_transit_to_carbon"
        />
      )
    },
    accessor: 'total_in_transit_to_carbon',
  },
  {
    id: 'total_in_transit_from_carbon',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="In Transit From Carbon"
          headerId="total_in_transit_from_carbon"
        />
      )
    },
    accessor: 'total_in_transit_from_carbon',
  },
]

export const RELAYER_COLUMNS: Array<Column<RelayerData>> = [
  {
    id: 'blockchain',
    Header: 'Blockchain',
    accessor: 'blockchain',
  },
  {
    id: 'sender',
    Header: "Address",
    accessor: 'sender',
  },
  {
    id: 'events_count',
    Header:"No. of events",
    accessor: 'events_count',
  },
  {
    id: 'most_recent_event',
    Header: "Last event",
    accessor: (row) => {
      return row.most_recent_event
        ? formatUTCEpochTime(parseInt(row.most_recent_event))
        : '-';
    },

  },
]

export const QUEUE_COLUMNS: Array<Column<QueueData>> = [
  {
    id: 'name',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Name"
          headerId="name"
        />
      )
    },
    accessor: 'name',
  },
  {
    id: 'waiting',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Waiting"
          headerId="waiting"
        />
      )
    },
    accessor: 'waiting',
  },
  {
    id: 'delayed',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Delayed"
          headerId="delayed"
        />
      )
    },
    accessor: 'delayed',
  },
  {
    id: 'active',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Active"
          headerId="active"
        />
      )
    },
    accessor: 'active',
  },
  {
    id: 'failed',
    Header: (prop: any) => {
      return (
        <SortableHeader
          handleSort={prop.onSort}
          headerName="Failed"
          headerId="failed"
        />
      )
    },
    accessor: 'failed',
  },
]
