import React from 'react'
import 'src/components/shared/AttributeTable.css'

type props = {
  children: any
}

const AttributeTable: React.FC<props> = ({ children }) => {
  return (
    <table className='attribute-table'>
      <tbody>
      {children}
      </tbody>
    </table>
  )
}

export default AttributeTable
