import { NavLink } from 'react-router-dom'
import { HydrogenLogo } from '../../assets/components'
import './Brand.css'

const Brand: React.FC = () => {
  return (
    <div>
      <div className='logo'>
        <NavLink to='/' id='logo-image' >
          <HydrogenLogo />
        </NavLink>
      </div>
    </div>
  )
}

export default Brand
