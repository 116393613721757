import { useEffect, useState } from 'react'
import { SortingRule, usePagination, useSortBy } from 'react-table'
import {
  FilterData,
  FilterState,
  QueueData,
  MonitorData,
  RelayerData
} from '../../constants/DataModels'
import { requestMonitor } from '../../store/monitor/MonitorActions'
import { MonitorState } from '../../store/monitor/MonitorTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import Card from '../Card/Card'
import './RelayersPage.css'
import Table, { TableConfig } from '../Tables/Table'
import { QUEUE_COLUMNS, MONITOR_COLUMNS, RELAYER_COLUMNS } from '../Tables/TableColumns'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { StatsState } from '../../store/stats/StatsTypes'
import { requestOverviewData } from '../../store/stats/StatsActions'
import { QueueState } from '../../store/queue/QueueTypes'
import { requestQueue } from '../../store/queue/QueueActions'
import { requestRelayer } from '../../store/relayers/RelayerActions'
import { RelayerState } from '../../store/relayers/RelayerTypes'

// page constants
const PAGE_SIZE = 25
const OFFSET = 0
const PAGE_INDEX = Math.ceil(OFFSET / PAGE_SIZE)
const FILTER_STATE = {}
const MONITOR_SORT_STATE = [
  {
    id: 'blockchain',
    desc: false,
  },
]
const QUEUE_SORT_STATE = [{ id: 'name', desc: false }]
const RELAYER_SORT_STATE = [{ id: 'blockchain', desc: false }] // may wanna change this to most recently created

const RelayersPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector

  // state from stats store
  const stats: StatsState = selector((state) => state.StatsReducer)
  const statsLoading: boolean = useTaskSubscriber('runStatsOverviewSaga')
  const overview = stats.overview
  const pendingUnlinked = overview.unlinked_count
  const inTransitFromCarbon = overview.in_transit_from_carbon_count
  const inTransitToCarbon = overview.in_transit_to_carbon_count

  // state from monitor store
  const monitors: MonitorState = selector((state) => state.MonitorReducer)
  const monitorData = monitors.data.data
  const monitorLoading: boolean = useTaskSubscriber('runMonitorSaga')
  const [monitorError, setMonitorError] = useState<Error | null>(null)
  // with pagination enabled
  const monitorPagination = monitors.data.pagination
  const [monitorPageCount, setMonitorPageCount] = useState(
    Math.ceil(monitorPagination.total / PAGE_SIZE)
  )

  // state from queue store
  const queue: QueueState = selector((state) => state.QueueReducer)
  const queueData = queue.data.data
  const queueLoading: boolean = useTaskSubscriber('runQueueSaga')
  const [queueError, setQueueError] = useState<Error | null>(null)
  // with pagination enabled
  const queuePagination = queue.data.pagination
  const [queuePageCount, setQueuePageCount] = useState(
    Math.ceil(queuePagination.total / PAGE_SIZE)
  )

  // state from relayers store
  const relayers: RelayerState = selector((state) => state.RelayerReducer)
  const relayerData = relayers.data.data
  const relayerLoading: boolean = useTaskSubscriber(`runRelayerSaga`)
  const [relayerError, setRelayerError] = useState<Error | null>(null)
  // with pagination enabled
  const relayerPagination = relayers.data.pagination
  const [relayerPageCount, setRelayerPageCount] = useState(
    Math.ceil(relayerPagination.total / PAGE_SIZE)
  )

  const network = selector((state) => state.NetworkReducer.network)

  // get card data
  const handleOverviewRequest = () => {
    try {
      dispatch(requestOverviewData())
    } catch (e: any) {
      console.error(e)
    }
  }

  // get monitors data
  const handleMonitorRequest = (
    offset: number,
    pageSize: number,
    filterState: FilterState,
    sortState: SortingRule<MonitorData>[]
  ) => {
    try {
      dispatch(requestMonitor(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setMonitorError(e)
    }
  }

  // get queue data
  const handleQueueRequest = (
    offset: number,
    pageSize: number,
    filterState: FilterState,
    sortState: SortingRule<QueueData>[]
  ) => {
    try {
      dispatch(requestQueue(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setQueueError(e)
    }
  }

  // get relayers data
  const handleRelayerRequest = (
    offset: number,
    pageSize: number,
    filterState: FilterState,
    sortState: SortingRule<RelayerData>[]
  ) => {
    try {
      dispatch(requestRelayer(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setRelayerError(e)
    }
  }

  // initial render to set states
  useEffect(() => {
    handleMonitorRequest(
      OFFSET,
      PAGE_SIZE,
      FILTER_STATE,
      MONITOR_SORT_STATE
    )

    handleRelayerRequest(
      OFFSET,
      PAGE_SIZE,
      FILTER_STATE,
      RELAYER_SORT_STATE
    )

    handleQueueRequest(
      OFFSET,
      PAGE_SIZE,
      FILTER_STATE,
      QUEUE_SORT_STATE
    )

    handleOverviewRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network])

  // set initial page count
  useEffect(() => {
    setMonitorPageCount(Math.ceil(monitorPagination.total / PAGE_SIZE))
    setQueuePageCount(Math.ceil(queuePagination.total / PAGE_SIZE))
    setRelayerPageCount(Math.ceil(relayerPagination.total / PAGE_SIZE))
  }, [monitorPagination.total, queuePagination.total, relayerPagination.total])

  // monitor table config
  const monitorTableConfig: TableConfig<MonitorData> = {
    options: {
      columns: MONITOR_COLUMNS,
      data: monitorData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: MONITOR_SORT_STATE,
      },
      manualPagination: true,
      pageCount: monitorPageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  // queue table config
  const queueTableConfig: TableConfig<QueueData> = {
    options: {
      columns: QUEUE_COLUMNS,
      data: queueData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: QUEUE_SORT_STATE,
      },
      manualPagination: true,
      pageCount: queuePageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  // relayer table config
  const relayerTableConfig: TableConfig<RelayerData> = {
    options: {
      columns: RELAYER_COLUMNS,
      data: relayerData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: RELAYER_SORT_STATE,
      },
      manualPagination: true,
      pageCount: relayerPageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  // pagination enabled for monitors table
  const monitorPaginationConfig = {
    paginationData: monitorPagination,
    onPageCountChange: setMonitorPageCount,
  }

  // pagination enabled for queues table
  const queuePaginationConfig = {
    paginationData: queuePagination,
    onPageCountChange: setQueuePageCount,
  }

  // pagination enabled for relayers table
  const relayerPaginationConfig = {
    paginationData: relayerPagination,
    onPageCountChange: setRelayerPageCount,
  }

  // filters disabled
  const filterConfig = {
    filterOptions: {} as FilterData[],
    onAddFilter: () => {},
    onRemoveFilter: () => {},
    filterState: FILTER_STATE,
  }

  return (
    <div className='container main-wrapper'>
      <div className='row cards'>
        <div className='column'>
          <Card
            title='Total unlinked'
            description={statsLoading ? '0' : pendingUnlinked.toString()}
          />
        </div>
        <div className='column'>
          <Card
            title='Total In-Transit From Carbon'
            description={statsLoading ? '0' : inTransitFromCarbon.toString()}
          />
        </div>
        <div className='column'>
          <Card
            title='Total In-Transit To Carbon'
            description={statsLoading ? '0' : inTransitToCarbon.toString()}
          />
        </div>
      </div>
      <div className='relayer_table'>
        <Table
          tableConfig={relayerTableConfig}
          isLoading={relayerLoading}
          error={relayerError}
          name='RELAYERS'
          onDataRequest={handleRelayerRequest}
          paginationConfig={relayerPaginationConfig}
          filterConfig={filterConfig}
        />
      </div>

      <div className='monitor-table'>
        <Table
          tableConfig={monitorTableConfig}
          isLoading={monitorLoading}
          error={monitorError}
          name='MONITORS'
          onDataRequest={handleMonitorRequest}
          paginationConfig={monitorPaginationConfig}
          filterConfig={filterConfig}
        />
      </div>
      <div className='queue-table'>
        <Table
          tableConfig={queueTableConfig}
          isLoading={queueLoading}
          error={queueError}
          name='QUEUE OVERVIEW'
          onDataRequest={handleQueueRequest}
          paginationConfig={queuePaginationConfig}
          filterConfig={filterConfig}
        />
      </div>
    </div>
  )
}

export default RelayersPage
