import * as React from "react";
const SvgRedditWhite = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 50 50"
    {...props}
  >
    <g clipPath="url(#reddit-white_svg__a)">
      <path
        fill="#fff"
        d="M50 24.54c0-3.04-2.483-5.51-5.535-5.51-1.49 0-2.84.595-3.834 1.553-3.77-2.48-8.873-4.06-14.523-4.262l3.09-9.727 8.367 1.96-.013.121c0 2.485 2.031 4.506 4.53 4.506 2.495 0 4.524-2.02 4.524-4.506 0-2.485-2.031-4.508-4.525-4.508a4.529 4.529 0 0 0-4.21 2.873l-9.019-2.115a.771.771 0 0 0-.917.519L24.49 16.292c-5.913.07-11.27 1.662-15.209 4.218a5.506 5.506 0 0 0-3.748-1.483C2.481 19.027 0 21.5 0 24.54c0 2.02 1.11 3.773 2.744 4.73a9.827 9.827 0 0 0-.18 1.786c0 8.148 10.017 14.777 22.332 14.777 12.314 0 22.333-6.629 22.333-14.777 0-.57-.06-1.133-.156-1.687 1.733-.931 2.927-2.734 2.927-4.83Zm-35.883 3.783a3.293 3.293 0 0 1 3.296-3.281 3.291 3.291 0 0 1 3.293 3.28 3.29 3.29 0 0 1-3.293 3.28c-1.817 0-3.296-1.47-3.296-3.28Zm18.877 9.727c-1.66 1.652-4.267 2.456-7.967 2.456L25 40.5l-.027.006c-3.702 0-6.308-.804-7.967-2.456a.768.768 0 0 1 0-1.09.776.776 0 0 1 1.096 0c1.354 1.348 3.602 2.002 6.87 2.002l.028.007.027-.007c3.269 0 5.517-.656 6.87-2.004a.778.778 0 0 1 1.097 0 .77.77 0 0 1 0 1.092Zm-.394-6.448a3.29 3.29 0 0 1-3.294-3.28 3.292 3.292 0 0 1 3.294-3.28 3.291 3.291 0 0 1 3.294 3.28 3.29 3.29 0 0 1-3.294 3.28Z"
      />
    </g>
    <defs>
      <clipPath id="reddit-white_svg__a">
        <path fill="#fff" d="M0 0h50v50H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRedditWhite;
