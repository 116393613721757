import { TokenActionTypes } from './TokenActions'
import { PaginationData, TokenData } from 'src/constants/DataModels'
import { TokenState, TokenTypes } from './TokenTypes'


const initialState: TokenState = {
  token: {
    data: [],
    pagination: {} as PaginationData,
  },
  tokenIdToData: {} as { [id: string]: TokenData }
}

const TokenReducer = function (state = initialState, action: TokenTypes) {
  switch (action.type) {
    case TokenActionTypes.SET_TOKEN_DATA: {
      return {
        ...state,
        token: action.payload,
        tokenIdToData: Object.fromEntries(action.payload.data.map((v) => [v.id, v])),
      }
    }
    default: {
      return state
    }
  }
}

export default TokenReducer
