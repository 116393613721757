import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Cross, Search } from '../../assets/components'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { addRelayFilter, removeRelayFilter, requestSearch } from '../../store/relays/RelayActions'
import { RelayState } from '../../store/relays/RelayTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import './SearchBar.css'
const SearchBar: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector
  const navigate = useNavigate()
  let location = useLocation()

  const [query, setQuery] = useState('')

  // state from relays store
  const relays: RelayState = selector((state) => state.RelayReducer)
  const search = relays.search.data
  const searchLoading: boolean = useTaskSubscriber('runSearchSaga')

  const handleSearch = (event: any) => {
    event.preventDefault()
    try {
      dispatch(requestSearch(query))
    } catch (e: any) {
      console.error(e)
    }
  }

  // const handleRemoveFilter = () => {
  //   dispatch(removeRelayFilter('search_term'))
  // }

  useEffect(() => {
    if (search.length === 1) {
      navigate(`/relays/${search[0].id}`)
    } else if (!!query) {
      dispatch(addRelayFilter('search_term', query))
      navigate(`/relays?search_term=${query}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    setQuery('')
  }, [location.pathname])

  return (
    <div>
      <form className="search-form" onSubmit={(event) => handleSearch(event)}>
        <div className="search-bar">
          <input className="search-input" type="text" value={query} placeholder="Enter a txn hash or address..." onChange={(event) => setQuery(event.target.value)} />
          <button className="search-icon-button" type="submit">
            <Search height="2em" width="2em" />
          </button>

          {/* {query ? (
            <button className="search-icon-button" onClick={() => handleRemoveFilter()}>
              <Cross height="2em" width="2em" />
            </button>
          ) : (
            <div className="search-icon-button">
              <Search height="2em" width="2em" />
            </div>
          )} */}

          {/* {searchLoading ? (
            <div className="search-icon-button">Loading...</div>
          ) : (
            <button className="search-icon-button" type="submit">
              <Search height="2em" width="2em" />
            </button>
          )} */}
        </div>
      </form>
    </div>
  )
}

export default SearchBar
