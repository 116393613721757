import { all, call, fork, put, takeEvery } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
  FilterState,
  RelayData,
  RelayDetailModel,
  RelayModel,
} from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import {
  RelayActionTypes,
  setBridgeTxDetail,
  setRelay,
  setSearch,
  setTxDetail,
} from '../relays/RelayActions'
import {
  RequestBridgeTxDetail,
  RequestRelay,
  RequestSearch,
  RequestTxDetail,
} from '../relays/RelayTypes'

//watchers
function* getRelayWatcher() {
  yield takeLatest(RelayActionTypes.REQUEST_RELAY, getRelayData)
}

function* getTxDetail() {
  yield takeEvery(RelayActionTypes.REQUEST_TX, getTxDetailData)
}

function* getSearch() {
  yield takeEvery(RelayActionTypes.REQUEST_SEARCH, getSearchData)
}

function* getBridgeTxDetail() {
  yield takeEvery(RelayActionTypes.REQUEST_BRIDGE_TX, getBridgeTxDetailData)
}

//workers
function* getRelayData(action: RequestRelay) {
  yield runSagaTask('runRelaySaga', function* () {
    const relayData = (yield call(
      getRelayDataAPI,
      action.offset,
      action.limit,
      action.filterState,
      action.sortState
    )) as AxiosResponse<RelayModel>
    yield put(setRelay(relayData.data))
  })
}

function* getTxDetailData(action: RequestTxDetail) {
  yield runSagaTask('runTxDetailSaga', function* () {
    const txDetailData = (yield call(
      getTxDetailDataAPI,
      action.id
    )) as AxiosResponse<RelayDetailModel>
    yield put(setTxDetail(txDetailData.data))
  })
}

function* getSearchData(action: RequestSearch) {
  yield runSagaTask('runSearchSaga', function* () {
    const searchData = (yield call(
      getSearchDataAPI,
      action.query
    )) as AxiosResponse<RelayModel>
    yield put(setSearch(searchData.data))
  })
}

function* getBridgeTxDetailData(action: RequestBridgeTxDetail) {
  yield runSagaTask('runTxDetailSaga', function* () {
    const txDetailData = (yield call(
      getTxDetailDataAPI,
      action.id
    )) as AxiosResponse<RelayDetailModel>
    yield put(setBridgeTxDetail(txDetailData.data))
  })
}

//API call
async function getRelayDataAPI(
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<RelayData>
) {
  let filters = ''
  const filterKeys = Array.from(Object.keys(filterState))
  filterKeys.forEach((filter: any) => {
    filters += `${filter}=${filterState[filter]}&`
  })

  const sort = sortState.desc ? '-' + sortState.id : sortState.id

  return await API.get<RelayModel>(
    `/relays?sort=${sort}&offset=${offset}&limit=${limit}&${filters}`
  )
}

async function getTxDetailDataAPI(id: string) {
  return await API.get<RelayDetailModel>(`/relays/${id}`)
}

async function getSearchDataAPI(query: string) {
  return await API.get<RelayDetailModel>(`/relays/search?search_term=${query}`)
}

export function* RelaySaga() {
  yield all([fork(getRelayWatcher), fork(getTxDetail), fork(getSearch), fork(getBridgeTxDetail)])
}
