import { PaginationData } from '../../constants/DataModels'
import {
  TransferPayloadTypes,
  TransferPayloadState,
} from './TransferPayloadTypes'
import { TransferPayloadActionTypes } from './TransferPayloadActions'
import {
  DEFAULT_END_DATE_FORMAT,
  DEFAULT_START_DATE_FORMAT,
} from '../../components/Tables/DateRangeFilter'

const initialState: TransferPayloadState = {
  data: {
    data: [],
    pagination: {} as PaginationData,
  },
  filters: {
    start_time: DEFAULT_START_DATE_FORMAT,
    end_time: DEFAULT_END_DATE_FORMAT,
  },
  sort: {
    id: '',
    desc: false
  }
}

//TODO: Add fail action
const TransferPayloadReducer = function (
  state = initialState,
  action: TransferPayloadTypes
) {
  switch (action.type) {

    case TransferPayloadActionTypes.SET_TRANSFERPAYLOAD: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case TransferPayloadActionTypes.ADD_FILTER: {
      let newFilterState = { ...state.filters }
      newFilterState[action.column] = action.row
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case TransferPayloadActionTypes.REMOVE_FILTER: {
      let newFilterState = { ...state.filters }
      delete newFilterState[action.column]
      return {
        ...state,
        filters: newFilterState,
      }
    }

    case TransferPayloadActionTypes.SET_SORT: {
      return {
        ...state,
        sort: {
          id: action.id,
          desc: action.desc
        },
      }
    }

    default: {
      return state
    }
  }
}

export default TransferPayloadReducer
