import { getTransactionURL } from 'src/constants/BlockchainConstants'
import { useAppSelector } from 'src/store/store'
import { maskHash } from 'src/constants/ConversionUtils'
import { Copy, Tick } from 'src/assets/components'
import 'src/components/shared/ExplorerTxHashLink.css'
import { useState } from 'react'

type Props = {
  blockchain: string
  hash: string
  shouldTruncate?: boolean
}

export const ExplorerTxHashLink: React.FC<Props> = ({
                                                      blockchain,
                                                      hash,
                                                      shouldTruncate = false,
                                                    }) => {
  const [copied, setCopied] = useState(false)
  const selector = useAppSelector
  const network = selector((state) => state.NetworkReducer.network)
  if (!hash || !blockchain) {
    return <div>{'-'}</div>
  }

  // link to transactions page in respective explorers
  const explorerUrl = getTransactionURL(blockchain, hash, network)
  if (!explorerUrl) {
    return <div>{hash} (Link not found)</div>
  }

  const formattedHash = shouldTruncate ? maskHash(hash, 6) : hash

  const handleCopy = () => {
    navigator.clipboard.writeText(hash)
    setCopied(true);

    // Revert back to the copy icon after 2 seconds
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <div className="link-container">
      <a href={explorerUrl} className="link" target="_blank" rel="noreferrer">
        {formattedHash}
      </a>
      {' '}
      <div onClick={handleCopy} className="copy-icon" style={{ cursor: 'pointer' }}>
        {copied ? <Tick /> : <Copy />}
      </div>
    </div>
  )
}
