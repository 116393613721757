/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { SortingRule, usePagination, useSortBy } from 'react-table'
import { EventData, FilterData, FilterState } from '../../constants/DataModels'
import Table, { TableConfig } from '../Tables/Table'
import { EVENT_COLUMNS } from '../Tables/TableColumns'

import { addEventFilter, removeEventFilter, requestEvent } from '../../store/events/EventActions'

import { EventState } from '../../store/events/EventTypes'

import { formatChain, formatLinkStatus, formatProcessingStatus } from 'src/constants/ConversionUtils'
import { DEFAULT_FILTER_STATE, DEFAULT_OFFSET, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_SORT_STATE } from 'src/constants/PageConstants'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { requestBlockchainData } from '../../store/stats/StatsActions'
import { StatsState } from '../../store/stats/StatsTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import Breadcrumb from '../Breadcrumb/Breadcrumb'
import './EventsPage.css'
import { EventNames } from 'src/constants/BlockchainConstants'

const EventsPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector

  // state from events store
  const events: EventState = selector((state) => state.EventReducer)
  const [eventError, setEventError] = useState<Error | null>(null)
  const eventLoading: boolean = useTaskSubscriber('runEventSaga')

  // with pagination and filters enabled
  const { pagination, data } = events.data
  const filterState = events.filters
  const [pageCount, setPageCount] = useState(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))

  // state from supported_blockchains
  const [blockchainOptions, setBlockchainOptions] = useState([''])
  const blockchains: StatsState = selector((state) => state.StatsReducer)
  const network = selector((state) => state.NetworkReducer.network)

  // format blockchains for filter options
  const formatFilterOptions = () => {
    const blockchainStats = blockchains.blockchains.data
    const formattedBlockchains = Array.from(new Set(blockchainStats.filter((row) => row.blockchain !== 'polynetwork').map((row) => row.blockchain))).sort()

    setBlockchainOptions(formattedBlockchains)
  }

  // get table data for events table
  const handleDataRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<EventData>[]) => {
    try {
      dispatch(requestEvent(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setEventError(e)
    }
  }

  // handle remove filter
  const handleRemoveFilter = useCallback((column: string) => {
    dispatch(removeEventFilter(column))
  }, [])

  // handle add filter
  const handleAddFilter = useCallback((column: string, row: string) => {
    dispatch(addEventFilter(column, row))
  }, [])

  // initial render to set states
  useEffect(() => {
    handleDataRequest(DEFAULT_OFFSET, DEFAULT_PAGE_SIZE, DEFAULT_FILTER_STATE, DEFAULT_SORT_STATE)
    dispatch(requestBlockchainData())
  }, [network])

  // set initial page count and filter options state
  useEffect(() => {
    setPageCount(Math.ceil(pagination.total / DEFAULT_PAGE_SIZE))
    formatFilterOptions()
  }, [pagination.total])

  // filter options
  const filterOptions: FilterData[] = [
    {
      name: 'Blockchain',
      column: 'blockchain',
      rows: blockchainOptions,
      rowFormatter: formatChain,
    },
    {
      name: 'Start Height',
      column: 'start_block_height',
    },
    {
      name: 'End Height',
      column: 'end_block_height',
    },
    {
      name: 'Transaction Hash',
      column: 'tx_hash',
    },
    {
      name: 'Sender',
      column: 'sender',
    },
    {
      name: 'Event Name',
      column: 'name',
      rows: Object.values(EventNames)
    },
    {
      name: 'Processing Status',
      column: 'processing_status',
      rows: ['processed', 'unprocessed', 'error'],
      rowFormatter: formatProcessingStatus,
    },
    {
      name: 'Link Status',
      column: 'link_status',
      rows: ['unlinked', 'linked'],
      rowFormatter: formatLinkStatus,
    },
  ]

  // table config
  const tableConfig: TableConfig<EventData> = {
    options: {
      columns: EVENT_COLUMNS,
      data: data,
      initialState: {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: DEFAULT_SORT_STATE,
      },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
    },
    plugins: [useSortBy, usePagination],
  }

  //pagination enabled
  const paginationConfig = {
    paginationData: pagination,
    onPageCountChange: setPageCount,
  }
  //filters enabled
  const filterConfig = {
    filterOptions: filterOptions,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
    filterState: filterState,
    dateRangeFilter: true,
  }
  return (
    <div>
      <div className="container main-wrapper">
        <Breadcrumb pages={['home', 'events']} />
        <Table tableConfig={tableConfig} isLoading={eventLoading} error={eventError} name="events" onDataRequest={handleDataRequest} paginationConfig={paginationConfig} filterConfig={filterConfig} />
      </div>
    </div>
  )
}

export default EventsPage
