import React from 'react'
import 'src/components/DetailsPage/sections/Event.css'
import { EventData } from 'src/constants/DataModels'
import ReactJson from 'react-json-view'
import AttributeTable from 'src/components/shared/AttributeTable'
import AttributeRow from 'src/components/shared/AttributeRow'

type CardProps = {
  eventInfo?: EventData
}


const Event: React.FC<CardProps> = ({ eventInfo }) => {
  if (!eventInfo) {
    return <div>Not Available</div>
  }
  const {
    index,
    name,
    event_params,
  } = eventInfo
  return (
    <AttributeTable>
        <AttributeRow name={'Index'}>{index}</AttributeRow>
        <AttributeRow name={'Name'}>{name}</AttributeRow>
        <AttributeRow name={'Event Params'}>
          {
            typeof event_params === 'string' ?
              event_params :
              <ReactJson src={event_params} style={ { overflowY: 'auto' } } shouldCollapse={({ name }) => name === 'root'}/>
          }
        </AttributeRow>
      </AttributeTable>
  )
}

export default Event
