import { Middleware } from 'redux'
import { Network } from 'src/constants'
import { networksToAPIMap } from 'src/constants/NavigationConstants'
import API from 'src/store/api/api'
import { NetworkActionTypes } from 'src/store/network/NetworkActionTypes'
import { NetworkReducerState, initialState as networkInitialState } from 'src/store/network/NetworkReducer'

const localStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const { type } = action
  switch (type) {
    case NetworkActionTypes.SET_NETWORK: {
      localStorage.setItem(NetworkActionTypes.SET_NETWORK, action.network)
      break
    }
  }
  return next(action)
}

export const loadLocalStorageState = () => {
  const netUrlParams = new URLSearchParams(window.location.search).get('net')
  const network = localStorage.getItem(NetworkActionTypes.SET_NETWORK)
  const networkState: NetworkReducerState = networkInitialState

  if (netUrlParams && (Object.values(Network).includes(netUrlParams as Network))) {
    networkState.network = netUrlParams as Network
  } else if (network) {
    networkState.network = network as Network
  }

  API.defaults.baseURL = networksToAPIMap.get(networkState.network)

  return {
    NetworkReducer: {
      ...networkState
    },
  }
}

export default localStorageMiddleware
