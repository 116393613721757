import { getAddressURL } from 'src/constants/BlockchainConstants'
import { useAppSelector } from 'src/store/store'
import { maskHash } from 'src/constants/ConversionUtils'

type Props = {
  blockchain: string
  address: string
  shouldTruncate?: boolean
}

export const ExplorerAddressLink: React.FC<Props> = ({
  blockchain,
  address,
  shouldTruncate = false,
}) => {
  const selector = useAppSelector
  const network = selector((state) => state.NetworkReducer.network)
  if (!address || !blockchain) {
    return <div>{'-'}</div>
  }

  // link to transactions page in respective explorers
  const explorerUrl = getAddressURL(blockchain, address, network)
  if (!explorerUrl) {
    return <div>{address} (Link not found)</div>
  }

  const formattedHash = shouldTruncate ? maskHash(address, 6) : address
  return (
    <a href={explorerUrl} className={`link`} target="_blank" rel="noreferrer">
      {formattedHash}
    </a>
  )
}
