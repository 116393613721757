import {
  DiscordWhite,
  PoweredByCarbonWhite,
  RedditWhite,
  TelegramWhite,
  TwitterWhite,
} from 'src/assets/components'
import './PageFooter.css'
import {
  CarbonEcosystemLinks,
  CarbonResourceLinks,
  SocialMediaLinks,
} from 'src/constants/ResourceLinks'

const PageFooter: React.FC = () => {
  return (
    <div className="background">
      <div className="container footer-container">
        <div className="link-container">
          <div className="column info-container">
            <h1 className="footer-title">Ecosystem</h1>
            <a
              className="footer-link"
              href={CarbonEcosystemLinks.CarbonWebsite}
              target="_blank"
              rel="noopener noreferrer"
            >
              Carbon
            </a>
            <a
              className="footer-link"
              href={CarbonEcosystemLinks.Demex}
              target="_blank"
              rel="noopener noreferrer"
            >
              Demex
            </a>
            <a
              className="footer-link"
              href={CarbonEcosystemLinks.CarbonHub}
              target="_blank"
              rel="noopener noreferrer"
            >
              CarbonHub
            </a>
            <a
              className="footer-link"
              href={CarbonEcosystemLinks.CarbonScan}
              target="_blank"
              rel="noopener noreferrer"
            >
              CarbonScan
            </a>
          </div>
          <div className="column info-container">
            <h3 className="footer-title">Resources</h3>
            <a
              className="footer-link"
              href={CarbonResourceLinks.CarbonDocs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Developer Docs
            </a>
            <a
              className="footer-link"
              href={CarbonResourceLinks.CarbonGuide}
              target="_blank"
              rel="noopener noreferrer"
            >
              Carbon Guide
            </a>
            <a
              className="footer-link"
              href={CarbonResourceLinks.GitHub}
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
            <a
              className="footer-link"
              href={CarbonResourceLinks.CommunityFund}
              target="_blank"
              rel="noopener noreferrer"
            >
              Community Fund
            </a>
          </div>
        </div>
        <div className="column social-media-container">
          {/* <h3 className="footer-title">Social Media</h3> */}
          <div className="social-media-links">
            <a
              href={SocialMediaLinks.Twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterWhite className="social-media-icons" />
            </a>
            <a
              href={SocialMediaLinks.Telegram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramWhite className="social-media-icons" />
            </a>
            <a
              href={SocialMediaLinks.Discord}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordWhite className="social-media-icons" />
            </a>
            <a
              href={SocialMediaLinks.Reddit}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RedditWhite className="social-media-icons" />
            </a>
          </div>
          <div>
            <PoweredByCarbonWhite className="powered-by" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageFooter
