import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosResponse } from 'axios'
import { SortingRule } from 'react-table'
import { takeLatest } from 'redux-saga/effects'
import {
    FilterState,
    RelayerData,
    RelayerModel,
} from '../../constants/DataModels'
import API from '../api/api'
import { runSagaTask } from '../loadingTask/LoadingTaskHelper'
import { RelayerActionTypes, setRelayer } from '../relayers/RelayerActions'
import { RequestRelayer } from '../relayers/RelayerTypes'

//watchers
function* getRelayerWatcher() {
    yield takeLatest(RelayerActionTypes.REQUEST_RELAYER, getRelayerData)
}

//workers
function* getRelayerData(action: RequestRelayer) {
    yield runSagaTask('runRelayerSaga', function* () {
      const RelayerData = (yield call(
        getRelayerDataAPI,
        action.offset,
        action.limit,
        action.filterState,
        action.sortState
      )) as AxiosResponse<RelayerModel>
  
      yield put(setRelayer(RelayerData.data))
    })
  }
  
//API call
async function getRelayerDataAPI(
    offset: number,
    limit: number,
    filterState: FilterState,
    sortState: SortingRule<RelayerData>
  ) {
    let filters = ''
    const filterKeys = Array.from(Object.keys(filterState))
    filterKeys.forEach((filter: any) => {
      filters += `${filter}=${filterState[filter]}&`
    })
  
    const sort = sortState.desc ? '-' + sortState.id : sortState.id
  
    return await API.get<RelayerModel>(
      `/relayers?offset=${offset}&limit=${limit}`
    )
}
  
export function* RelayerSaga() {
    yield all([fork(getRelayerWatcher)])
}
  