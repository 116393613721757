import { NavBarLinkItem } from '../../constants/NavigationConstants'
import NavBarLink from './NavBarLink'
import './NavBarLinks.css'

type NavBarLinksProps = {
  mainNavBarLinks: NavBarLinkItem[]
  updateNavBarSelection: (selectedIndex: number, optionIndex?: number) => void
}

const NavBarLinks: React.FC<NavBarLinksProps> = ({
  mainNavBarLinks,
  updateNavBarSelection,
}) => {
  return (
    <div>
      <div className='nav-tab-row'>
        {mainNavBarLinks.map((navBarLink, index) => (
          <NavBarLink
            key={index}
            id={index}
            navBarLink={navBarLink}
            updateSelection={updateNavBarSelection}
          />
        ))}
      </div>
    </div>
  )
}
export default NavBarLinks
