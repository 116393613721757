import { SortingRule } from 'react-table'
import {
  RelayData,
  RelayModel,
  FilterState,
  RelayDetailModel,
} from '../../constants/DataModels'

import {
  RequestRelay,
  SetRelay,
  AddRelayFilter,
  RemoveRelayFilter,
  RequestTxDetail,
  SetTxDetail,
  SetRelaySort,
  RequestSearch,
  SetSearch,
  RequestBridgeTxDetail,
  SetBridgeTxDetail,
} from './RelayTypes'




export enum RelayActionTypes {
  REQUEST_RELAY = 'REQUEST_RELAY',
  SET_RELAY = 'SET_RELAY',
  ADD_FILTER = 'ADD_RELAY_FILTER',
  REMOVE_FILTER = 'REMOVE_RELAY_FILTER',
  REQUEST_TX = 'REQUEST_TX_DETAIL',
  SET_TX = 'SET_TX_DETAIL',
  SET_SORT = 'SET_RELAY_SORT',
  REQUEST_SEARCH = 'REQUEST_SEARCH',
  SET_SEARCH = 'SET_SEARCH',
  REQUEST_BRIDGE_TX = "REQUEST_BRIDGE_TX",
  SET_BRIDGE_TX = "SET_BRIDGE_TX"
}

export const requestRelay = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<RelayData>
): RequestRelay => {
  return {
    type: RelayActionTypes.REQUEST_RELAY,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setRelay = (
  data: RelayModel
): SetRelay => {
  return {
    type: RelayActionTypes.SET_RELAY,
    payload: data,
  }
}

export const addRelayFilter = (
  column: string,
  row: string
): AddRelayFilter => {
  return {
    type: RelayActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeRelayFilter = (
  column: string
): RemoveRelayFilter => {
  return {
    type: RelayActionTypes.REMOVE_FILTER,
    column,
  }
}

export const requestTxDetail = (id: string): RequestTxDetail => {
  return {
    type: RelayActionTypes.REQUEST_TX,
    id,
  }
}

export const setTxDetail = (data: RelayDetailModel): SetTxDetail => {
  return {
    type: RelayActionTypes.SET_TX,
    payload: data,
  }
}

export const setRelaySort = (
  id: string,
  desc: boolean
): SetRelaySort => {
  return {
    type: RelayActionTypes.SET_SORT,
    id,
    desc,
  }
}

export const requestSearch = (query: string): RequestSearch => {
  return {
    type: RelayActionTypes.REQUEST_SEARCH,
    query,
  }
}

export const setSearch = (data: RelayModel): SetSearch => {
  return {
    type: RelayActionTypes.SET_SEARCH,
    payload: data,
  }
}

export const requestBridgeTxDetail = (id: string): RequestBridgeTxDetail => {
  return {
    type: RelayActionTypes.REQUEST_BRIDGE_TX,
    id,
  }
}

export const setBridgeTxDetail = (data: RelayDetailModel): SetBridgeTxDetail => {
  return {
    type: RelayActionTypes.SET_BRIDGE_TX,
    payload: data,
  }
}