import { formatChain } from '../../constants/ConversionUtils'

type blockchainProps = {
  blockchain: string
}



export const BlockchainColumn = ({ blockchain }: blockchainProps) => {
  return <div>{formatChain(blockchain)}</div>
}
