import { format, utcToZonedTime } from 'date-fns-tz'
import { capitalize } from 'lodash'
import { FlowType, FromCarbonPayloadType, ToCarbonPayloadType } from 'src/constants/BlockchainConstants'
import { To } from 'react-router-dom'

export const maskHash = (hash: string, len: number) => {
  if (hash.length > len + 3) {
    return hash.slice(0, len) + '...' + hash.slice(-3)
  } else {
    return hash
  }
}

export const uuidv4 = () => {
  // eslint-disable-line import/prefer-default-export
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0 // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line no-bitwise
    return v.toString(16)
  })
}

export const formatBridge = (bridge: string) => {
  switch (bridge) {
    case 'polynetwork':
      return 'Poly Network'
    case 'ibc':
      return 'IBC'
    case undefined:
    case null:
      return '---'
    default:
      return capitalize(bridge)
  }
}


export const formatChain = (chain: string) => {
  switch (chain) {
    case 'eth':
      return 'Ethereum'
    case 'zil':
      return 'Zilliqa'
    case 'bsc':
      return 'Binance Smart Chain'
    case 'neo':
      return 'NEO'
    case 'neo3':
      return 'NEO3'
    case 'polynetwork':
      return 'Poly Network'
    case undefined:
    case null:
      return '---'
    default:
      return capitalize(chain)
  }
}

export const formatHeight = (height: number) => {
  if (height >= 1 && height <= 1) {
    return '1'
  } else if (height >= 1 && height <= 10000) {
    return '1000'
  } else if (height > 10000 && height <= 50000) {
    return '10000'
  } else if (height > 50000 && height <= 100000) {
    return '100000'
  } else {
    return 'Other'
  }
}

export enum FormattedRelayStatus {
  InTransit = 'In Transit',
  Completed = 'Complete',
  Refunded = 'Refunded',
  Error = 'Error',
}

export const formatRelayStatus = (status: string) => {
  if (status === 'in_transit') {
    return FormattedRelayStatus.InTransit
  } else if (status === 'completed') {
    return FormattedRelayStatus.Completed
  } else if (status === 'refunded') {
    return FormattedRelayStatus.Refunded
  } else {
    return FormattedRelayStatus.Error
  }
}

export const formatPayloadType = (bridge: string, flowType: FlowType, payloadType: string) => {
  if (!bridge || !flowType) {
    return ''
  }
  if (bridge !== 'axelar') {
    return capitalize(payloadType)
  }
  if (flowType === FlowType.In) {
    return toCarbonPayloadTypeToString(payloadType as ToCarbonPayloadType)
  } else if (flowType === FlowType.Out) {
    return fromCarbonPayloadTypeToString(payloadType as FromCarbonPayloadType)
  }
}

function toCarbonPayloadTypeToString(value: ToCarbonPayloadType): string {
  switch (value) {
    case ToCarbonPayloadType.Deposit:
      return "Deposit";
    case ToCarbonPayloadType.DepositAndExecute:
      return "DepositAndExecute";
    case ToCarbonPayloadType.ExecuteCarbon:
      return "ExecuteCarbon";
    case ToCarbonPayloadType.ConfirmationCallback:
      return "ConfirmationCallback";
    default:
      return "Unknown";
  }
}

function fromCarbonPayloadTypeToString(value: FromCarbonPayloadType): string {
  switch (value) {
    case FromCarbonPayloadType.Withdraw:
      return "Withdraw";
    case FromCarbonPayloadType.ExecuteGateway:
      return "ExecuteGateway";
    case FromCarbonPayloadType.WithdrawAndExecute:
      return "WithdrawAndExecute";
    case FromCarbonPayloadType.RegisterToken:
      return "RegisterToken";
    case FromCarbonPayloadType.DeregisterToken:
      return "DeregisterToken";
    default:
      return "Unknown";
  }
}

export const formatLinkStatus = (status: string) => {
  if (status === 'unlinked') {
    return 'Unlinked'
  } else if (status === 'linked') {
    return 'Linked'
  } else {
    return 'Error'
  }
}

export const formatProcessingStatus = (status: string) => {
  if (status === 'processed') {
    return 'Processed'
  } else if (status === 'unprocessed') {
    return 'Unprocessed'
  } else {
    return 'Error'
  }
}

export const formatTime = (time: number | string | Date) => {
  if (!time) return '-'
  return format(utcToZonedTime(new Date(time), 'UTC'), 'yyyy-MM-dd HH:mm:ss')
}

export const formatTimeMobile = (time: number | string | Date) => {
  if (!time) return '-'
  return format(utcToZonedTime(new Date(time), 'UTC'), 'dd-MM-yy HH:mm')
}

export const formatAmount = (amt: string, decimals: number) => {
  let formattedAmount
  const amtLength = amt.length

  if (decimals === 0) {
    formattedAmount = amt
  } else if (decimals === amtLength) {
    formattedAmount = '0.' + amt
  } else if (decimals >= amtLength) {
    const zeroes = decimals - amtLength
    formattedAmount = '0.' + '0'.repeat(zeroes) + amt
  } else {
    const whole = amt.slice(0, -decimals)
    const fractional = amt.slice(-decimals)
    formattedAmount = whole + '.' + fractional
  }

  const trimmedAmount = parseFloat(formattedAmount).toString()
  return trimmedAmount
}

function utcEpochToDate(epoch: number): string {
  const date = new Date((epoch * 1000) / 1000)
  const utcTime = date.toUTCString()
  return utcTime
}

export const formatUTCEpochTime = (time: number) => {
  if (!time) return '-'
  const utcEpoch = time // UTC epoch timestamp
  const date = utcEpochToDate(utcEpoch)
  return formatTime(date)
}


export const formatSnakeCaseToTitleCase = (input: string) => {
  const words = input.split('_')
  const capitalize = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )
  const output = capitalize.join(' ')
  return output
}

// copied from https://github.com/axios/axios/blob/fe7d09bb08fa1c0e414956b7fc760c80459b0a43/lib/helpers/combineURLs.js
export function combineURLs(baseURL: string, relativeURL: string) {
  return relativeURL
    ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
    : baseURL
}

// strips 0x from a string
export function strip0x(str: string) {
  return str.replace(/^0x/i, '')
}

// strips ibc/ from a string
export function stripIbc(str: string) {
  return str.replace(/^ibc\//i, '')
}
