import { useEffect, useRef, useState } from 'react'
import './DateRangeFilter.css'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import { format, zonedTimeToUtc } from 'date-fns-tz'
import { addDays, subMonths } from 'date-fns/esm'

type Props = {
  onAddFilter: (column: string, row: string) => void
}

export const DEFAULT_START_DATE = subMonths(new Date(), 3)
export const DEFAULT_END_DATE = addDays(new Date(), 1)
export const DEFAULT_START_DATE_FORMAT = format(
  zonedTimeToUtc(DEFAULT_START_DATE, 'UTC'),
  'yyyy-MM-dd'
)
export const DEFAULT_END_DATE_FORMAT = format(
  zonedTimeToUtc(DEFAULT_END_DATE, 'UTC'),
  'yyyy-MM-dd'
)

const DateRangeFilter: React.FC<Props> = ({ onAddFilter }) => {
  // date range state
  const [range, setRange] = useState([
    {
      startDate: DEFAULT_START_DATE,
      endDate: DEFAULT_END_DATE,
      key: 'selection',
    },
  ])

  // open close calender
  const [open, setOpen] = useState(false)

  // get target element to toggle
  const refOne = useRef(null)

  useEffect(() => {
    document.addEventListener('click', hideOnClickOutside, true)
  }, [])

  // hide date picker when click outside component
  const hideOnClickOutside = (event: any) => {
    const clickTarget: any = refOne.current
    if (clickTarget && !clickTarget.contains(event.target)) {
      setOpen(false)
    }
  }

  // set date range in store
  const handleSetRange = (selection: any) => {
    setRange([selection])
    const start = format(
      zonedTimeToUtc(selection.startDate, 'UTC'),
      'yyyy-MM-dd'
    )
    const end = format(zonedTimeToUtc(selection.endDate, 'UTC'), 'yyyy-MM-dd')
    onAddFilter('start_time', start)
    onAddFilter('end_time', end)
  }

  return (
    <div className='calender-wrap'>
      <input
        value={` ${format(range[0].startDate, 'yyyy-MM-dd')} to ${format(
          range[0].endDate,
          'yyyy-MM-dd'
        )} `}
        readOnly
        className='input-box'
        onClick={() => setOpen((open) => !open)}
      />
      <div ref={refOne}>
        {open && (
          <DateRangePicker
            onChange={(item) => {
              handleSetRange(item.selection)
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction='horizontal'
            inputRanges={[]}
            staticRanges={[
              ...defaultStaticRanges,
              {
                label: "All Time",
                range: () => ({
                  startDate: new Date("2019-01-01"),
                  endDate: new Date()
                }),
                isSelected(range) {
                  if (!(range.startDate && range.endDate)) return false
                  return (range.startDate <= new Date("2019-01-01")) || (range.endDate >= new Date());
                }
              }
            ]}
            className='calender-element'
          />
        )}
      </div>
    </div>
  )
}

export default DateRangeFilter
