import { SortingRule } from 'react-table'
import { MonitorModel, FilterState, MonitorData } from '../../constants/DataModels'

import {
  RequestMonitor,
  SetMonitor,
  AddMonitorFilter,
  RemoveMonitorFilter,
  SetMonitorSort,
} from './MonitorTypes'

export enum MonitorActionTypes {
  REQUEST_MONITOR = 'REQUEST_MONITOR',
  SET_MONITOR = 'SET_MONITOR',
  ADD_FILTER = 'ADD_MONITOR_FILTER',
  REMOVE_FILTER = 'REMOVE_MONITOR_FILTER',
  SET_SORT = 'SET_MONITOR_SORT',
}

export const requestMonitor = (
  offset: number,
  limit: number,
  filterState: FilterState,
  sortState: SortingRule<MonitorData>
): RequestMonitor => {
  return {
    type: MonitorActionTypes.REQUEST_MONITOR,
    offset,
    limit,
    filterState,
    sortState,
  }
}

export const setMonitor = (data: MonitorModel): SetMonitor => {
  return {
    type: MonitorActionTypes.SET_MONITOR,
    payload: data,
  }
}

export const addMonitorFilter = (
  column: string,
  row: string
): AddMonitorFilter => {
  return {
    type: MonitorActionTypes.ADD_FILTER,
    column,
    row,
  }
}

export const removeMonitorFilter = (column: string): RemoveMonitorFilter => {
  return {
    type: MonitorActionTypes.REMOVE_FILTER,
    column,
  }
}

export const setMonitorSort = (id: string, desc: boolean): SetMonitorSort => {
  return {
    type: MonitorActionTypes.SET_SORT,
    id,
    desc
  }
}
