
import { useEffect } from 'react'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { FilterData, FilterState } from '../../constants/DataModels'
import { Cross } from '../../assets/components'
import './InputFilter.css'

type filterProps = {
  filter: FilterData
  onAddFilter: (column: string, row: string) => void
  onRemoveFilter: (column: string) => void
  filterState: FilterState
}

const InputFilter: React.FC<filterProps> = ({
                                              filter,
                                              onAddFilter,
                                              onRemoveFilter,
                                              filterState,
                                            }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleAddFilter = (value: string) => {
    if (value.length === 0) {
      handleRemoveFilter()
      return
    }

    const queryParams = queryString.parse(location.search);
    queryParams[filter.column] = value;
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    }, { replace: true });
  }

  const handleRemoveFilter = () => {
    const queryParams = queryString.parse(location.search);
    delete queryParams[filter.column];
    navigate({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    }, { replace: true });
  }

  const getCurrentFilter = () => {
    return filterState[filter.column] ? filterState[filter.column] : ''
  }

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (typeof queryParams[filter.column] === 'string') {
      onAddFilter(filter.column, queryParams[filter.column] as string)
    } else {
      onRemoveFilter(filter.column);
    }
  }, [location, filter.column, onAddFilter, onRemoveFilter]);

  useEffect(() => {
    // Update the query parameters when the filter state changes
  }, [filter.column, filterState, navigate, location]);

  return (
    <div className="filter">
      <div className="container">

        <input
          className='table-input'
          type='text'
          placeholder={filter.name}
          value={getCurrentFilter()}
          onChange={(event) => handleAddFilter(event.target.value)}
        />
        {filterState[filter.column] ? (
          <button
            className="clear-filter-button"
            onClick={() => handleRemoveFilter()}
          >
            <Cross />
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default InputFilter
