import { Network } from '../../constants'
import { NetworkActionTypes } from './NetworkActionTypes'
import { NetworkTypes } from './NetworkTypes'

export type NetworkReducerState = {
  network: Network
}

export const initialState: NetworkReducerState = {
  network: Network.MainNet,
}

const NetworkReducer = function (
  state = initialState,
  action: NetworkTypes
): NetworkReducerState {
  switch (action.type) {
    case NetworkActionTypes.SET_NETWORK: {
      return {
        ...state,
        network: action.network,
      }
    }
    case NetworkActionTypes.UPDATE_NETWORK:
    default: {
      return state
    }
  }
}

export default NetworkReducer
