import { put } from 'redux-saga/effects'

import * as action from './LoadingTaskActions'
import { uuidv4 } from '../../constants/ConversionUtils'

export function* runSagaTask(
  taskname: string,
  task: () => Generator,
  handleError?: (error: Error) => Generator
) {
  const loadingUuid = uuidv4()
  yield put(action.addBackgroundLoading(taskname, loadingUuid))
  try {
    return yield* task()
  } catch (error) {
    if (handleError) {
      yield* handleError(error as Error)
    } else {
      console.error(error)
    }
    return null
  } finally {
    yield put(action.removeBackgroundLoading(loadingUuid))
  }
}
