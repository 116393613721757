import { getAssetURL } from 'src/constants/BlockchainConstants'
import { useAppSelector } from 'src/store/store'
import { maskHash } from 'src/constants/ConversionUtils'
import { TokenState } from 'src/store/token/TokenTypes'

type Props = {
  blockchain: string
  assetIdOrAddress: string
  shouldTruncate?: boolean
}

export const ExplorerAssetLink: React.FC<Props> = ({
  blockchain,
  assetIdOrAddress,
  shouldTruncate = false,
}) => {
  const selector = useAppSelector
  const network = selector((state) => state.NetworkReducer.network)
  const tokenState: TokenState = selector((state) => state.TokenReducer)

  if (!assetIdOrAddress || !blockchain) {
    return <div>{'-'}</div>
  }

  let id = assetIdOrAddress
  if (blockchain === 'carbon' || blockchain === 'switcheo') {
    const { tokenIdToData } = tokenState
    const token = tokenIdToData[assetIdOrAddress]
    if (token) {
      id = token.denom
    } else {
      return (
        <div>
          {`${
            shouldTruncate
              ? `${assetIdOrAddress.slice(0, 5)}...`
              : assetIdOrAddress
          }`}{' '}
          (Carbon token not found)
        </div>
      )
    }
  }

  // link to transactions page in respective explorers
  const explorerUrl = getAssetURL(blockchain, id, network)
  if (!explorerUrl) {
    return <div>{assetIdOrAddress.slice(0, 5)}... (Link not found)</div>
  }

  const formattedHash = shouldTruncate
    ? maskHash(assetIdOrAddress, 6)
    : assetIdOrAddress

  if (
    assetIdOrAddress === '0x0000000000000000000000000000000000000000' ||
    assetIdOrAddress === 'zil1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9yf6pz'
  ) {
    return <div>{formattedHash}</div>
  }
  return (
    <a href={explorerUrl} className={`link`} target="_blank" rel="noreferrer">
      {formattedHash}
    </a>
  )
}
