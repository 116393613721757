import { fork, all } from '@redux-saga/core/effects'
import { RelaySaga } from './saga/RelaySaga'
import { TransferPayloadSaga } from './saga/TransferPayloadSaga'
import { RegisterAssetSaga } from './saga/RegisterAssetSaga'
import { StatsSaga } from './saga/StatsSaga'
import { MonitorSaga } from './saga/MonitorSaga'
import { QueueSaga } from './saga/QueueSaga'
import { NetworkSaga } from './saga/NetworkSaga'
import { TokenSaga } from './saga/TokenSaga'
import { EventSaga } from './saga/EventSaga'
import { RelayerSaga } from './saga/RelayerSaga'

export function* rootSagas() {
  yield all([
    fork(RelaySaga),
    fork(TransferPayloadSaga),
    fork(RegisterAssetSaga),
    fork(StatsSaga),
    fork(MonitorSaga),
    fork(EventSaga),
    fork(QueueSaga),
    fork(NetworkSaga),
    fork(TokenSaga),
    fork(RelayerSaga),
  ])
}
