/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SortingRule } from 'react-table'
import { requestTokenData } from 'src/store/token/TokenActions'
import { FilterData, FilterState, PaginationData, RegisterAssetData, RelayData, TransferPayloadData } from '../../constants/DataModels'
import useTaskSubscriber from '../../hooks/useTaskSubscriber'
import { requestRegisterAsset } from '../../store/registerasset/RegisterAssetActions'
import { RegisterAssetState } from '../../store/registerasset/RegisterAssetTypes'
import { requestRelay } from '../../store/relays/RelayActions'
import { RelayState } from '../../store/relays/RelayTypes'
import { requestOverviewData } from '../../store/stats/StatsActions'
import { StatsState } from '../../store/stats/StatsTypes'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { requestTransferPayload } from '../../store/transfer/TransferPayloadActions'
import { TransferPayloadState } from '../../store/transfer/TransferPayloadTypes'
import Card from '../Card/Card'
import Table, { TableConfig } from '../Tables/Table'
import { REGISTER_ASSET_COLUMNS, RELAY_COLUMNS, TRANSFER_PAYLOAD_COLUMNS } from '../Tables/TableColumns'
import './HomePage.css'

// page constants
const PAGE_SIZE = 5
const PAGE_INDEX = 0
const OFFSET = 0
const FILTER_STATE = {}
const SORT_STATE = [
  {
    id: '',
    desc: false,
  },
]

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector
  const navigate = useNavigate()

  // network state between Mainnet or Testnet
  const network = selector((state) => state.NetworkReducer.network)

  // state from relays store
  const relays: RelayState = useAppSelector((state) => state.RelayReducer)
  const relayData = relays.data.data
  const [relayError, setRelayError] = useState<Error | null>(null)
  const relayLoading: boolean = useTaskSubscriber('runRelaySaga')

  // state from transfer payloads store
  const transferPayloads: TransferPayloadState = useAppSelector((state) => state.TransferPayloadReducer)

  const transferPayloadData = transferPayloads.data.data
  const [transferPayloadError, setTransferPayloadError] = useState<Error | null>(null)
  const transferPayloadLoading: boolean = useTaskSubscriber('runTransferPayloadSaga')

  // state from register assets store
  const registerAssets: RegisterAssetState = useAppSelector((state) => state.RegisterAssetReducer)
  const registerAssetData = registerAssets.data.data
  const [registerAssetError, setRegisterAssetError] = useState<Error | null>(null)
  const registerAssetLoading: boolean = useTaskSubscriber('runRegisterAssetSaga')

  // state from stats/overview store
  const stats: StatsState = selector((state) => state.StatsReducer)
  const statsLoading: boolean = useTaskSubscriber('runStatsOverviewSaga')
  const overview = stats.overview
  const totalRelay = overview.relay_count
  const totalInTransit = overview.in_transit_count
  const dayTx = stats.dayTx

  // get table data for relays table
  const handleRelayRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<RelayData>[]) => {
    try {
      dispatch(requestRelay(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setRelayError(e)
    }
  }

  // get table data for transfer payloads table
  const handleTransferPayloadRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<TransferPayloadData>[]) => {
    try {
      dispatch(requestTransferPayload(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setTransferPayloadError(e)
    }
  }

  // get table data for register assets table
  const handleRegisterAssetRequest = (offset: number, pageSize: number, filterState: FilterState, sortState: SortingRule<RegisterAssetData>[]) => {
    try {
      dispatch(requestRegisterAsset(offset, pageSize, filterState, sortState[0]))
    } catch (e: any) {
      setRegisterAssetError(e)
    }
  }

  // initial render to set states
  useEffect(() => {
    handleRelayRequest(OFFSET, PAGE_SIZE, FILTER_STATE, SORT_STATE)

    handleTransferPayloadRequest(OFFSET, PAGE_SIZE, FILTER_STATE, SORT_STATE)

    handleRegisterAssetRequest(OFFSET, PAGE_SIZE, FILTER_STATE, SORT_STATE)

    dispatch(requestTokenData())
    dispatch(requestOverviewData())
  }, [network])

  // relay table config
  const relayTableConfig: TableConfig<RelayData> = {
    options: {
      columns: RELAY_COLUMNS,
      data: relayData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: SORT_STATE,
      },
    },
    plugins: [],
  }

  // transfer payloads table config
  const transferPayloadTableConfig: TableConfig<TransferPayloadData> = {
    options: {
      columns: TRANSFER_PAYLOAD_COLUMNS,
      data: transferPayloadData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: SORT_STATE,
        hiddenColumns: ['from_asset_hash', 'to_asset_hash'],
      },
    },
    plugins: [],
  }

  // register assets table config
  const registerAssetTableConfig: TableConfig<RegisterAssetData> = {
    options: {
      columns: REGISTER_ASSET_COLUMNS,
      data: registerAssetData,
      initialState: {
        pageIndex: PAGE_INDEX,
        pageSize: PAGE_SIZE,
        sortBy: SORT_STATE,
      },
    },
    plugins: [],
  }

  //pagination disabled
  const paginationConfig = {
    paginationData: {
      total: 1,
    } as PaginationData,
    onPageCountChange: () => {},
  }

  //filters disabled
  const filterConfig = {
    filterOptions: {} as FilterData[],
    onAddFilter: () => {},
    onRemoveFilter: () => {},
    filterState: FILTER_STATE,
  }


  return (
    <div className="container main-wrapper">
        <div className="row dashboard-cards">
          <div className="column total-relays">
            <Card title="Total Relays" description={totalRelay.toString()} />
          </div>
          <div className="column h-relays">
            <Card title="24H Relays" description={dayTx.toString()} />
          </div>
          <div className="column in-progress-relays">
            <Card title="Relays in Progress" description={totalInTransit.toString()} />
          </div>
        </div>
      <div className="latest-table">
        <Table tableConfig={relayTableConfig} isLoading={relayLoading} error={relayError} name="latest relays" onDataRequest={handleRelayRequest} paginationConfig={paginationConfig} filterConfig={filterConfig} viewMorePath="relays" />
      </div>
      <div className="latest-table">
        <Table
          tableConfig={transferPayloadTableConfig}
          isLoading={transferPayloadLoading}
          error={transferPayloadError}
          name="latest transfers"
          onDataRequest={handleTransferPayloadRequest}
          paginationConfig={paginationConfig}
          filterConfig={filterConfig}
          viewMorePath="transfer_payloads"
        />
      </div>
      <div className="latest-table">
        <Table
          tableConfig={registerAssetTableConfig}
          isLoading={registerAssetLoading}
          error={registerAssetError}
          name="latest asset registrations"
          onDataRequest={handleRegisterAssetRequest}
          paginationConfig={paginationConfig}
          filterConfig={filterConfig}
          viewMorePath="register_assets"
        />
      </div>
    </div>
  )
}

export default HomePage
