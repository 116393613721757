export enum CarbonEcosystemLinks {
  CarbonWebsite = 'https://carbon.network/',
  Demex = 'https://app.dem.exchange/trade',
  CarbonHub = 'https://hub.carbon.network/',
  CarbonScan = 'https://scan.carbon.network/',
}

export enum CarbonResourceLinks {
  CarbonDocs = 'https://docs.carbon.network/',
  CarbonGuide = 'https://guide.carbon.network/',
  GitHub = 'https://github.com/Switcheo/carbon-bootstrap',
  CommunityFund = 'https://commonwealth.im/carbon-protocol/discussion/4486-how-to-apply-for-a-grant-under-the-switcheo-development-fund',
}

export enum SocialMediaLinks {
  Twitter = 'https://twitter.com/0xcarbon',
  Telegram = 'https://t.me/demex_community',
  Discord = 'https://discord.com/invite/SPh62Hf',
  Reddit = 'https://www.reddit.com/r/Switcheo/',
}
