// https://ant.design/components/icon/
import * as React from 'react'

const IconTick = (props) => (
  <svg
    viewBox="0 0 1024 1024"
    fill="currentColor"
    height="1em"
    width="1em"
    {...props}
  >
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zM461 707.5L263.1 509.6l57.6-57.6L461 592.3l242.3-242.3 57.6 57.6L461 707.5z"
    />
  </svg>
)

export default IconTick
